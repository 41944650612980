// import React, { useState, useEffect, useContext } from "react";
// import AuthContext from "../Components/AuthProvider";
// import { Button } from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import Swal from 'sweetalert2';
// import Select from 'react-select';
// import Rate_Chart_Service from "../services/Rate_Chart_Service";
// import { Card, CardBody, Container, Row, Col, Form, FormGroup, Label, Input, Table } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBackward, faClose, faPlaneCircleCheck, faPlaneUp, faRefresh, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';
// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { faCheck, faSave, faTimes, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate, useLocation } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';


// function Import_Model() {
//     const [errors, setErrors] = useState({});
//     const [companyId, setcompanyId] = useState('');

//     const [branchId1, setBranchId] = useState('');
//     const [impTransId, setImpTransId] = useState('');
//     const [impTransDate, setImpTransDate] = useState(); // Use appropriate initial value
//     const [mawb, setMawb] = useState('');
//     const [hawb, setHawb] = useState('');
//     const [igmNo, setIgmNo] = useState('');
//     const [igmDate, setIgmDate] = useState(''); // Use appropriate initial value
//     const [sirNo, setSirNo] = useState('');
//     const [sirDate, setSirDate] = useState(''); // Use appropriate initial value
//     const [pctmNo, setPctmNo] = useState('');
//     const [tpNo, setTpNo] = useState('');
//     const [tpDate, setTpDate] = useState(null); // Use appropriate initial value
//     const [airlineName, setAirlineName] = useState('');
//     const [flightNo, setFlightNo] = useState('');
//     const [flightDate, setFlightDate] = useState(); // Use appropriate initial value
//     const [countryOrigin, setCountryOrigin] = useState('');
//     const [portOrigin, setPortOrigin] = useState('');
//     const [importerId, setImporterId] = useState('');
//     const [iec, setIec] = useState('');
//     const [sezEntityId, setSezEntityId] = useState('');
//     const [consoleName, setconsoleName] = useState('');
//     const [packageContentType, setPackageContentType] = useState('GOLD JEWELLERY (GLJ)');
//     const [parcelType, setParcelType] = useState('');
//     const [uomPackages, setUomPackages] = useState('');
//     const [nop, setNop] = useState(1); // Use appropriate initial value
//     const [importRemarks, setImportRemarks] = useState('');
//     const [descriptionOfGoods, setDescriptionOfGoods] = useState('GLJ');
//     const [chaCde, setChaCde] = useState('');
//     const [assessableValue, setAssessableValue] = useState('');
//     const [grossWeight, setGrossWeight] = useState(''); // Use appropriate initial value
//     const [beRequestId, setBeRequestId] = useState('');
//     const [beNo, setBeNo] = useState('');
//     const [beDate, setBeDate] = useState(null); // Use appropriate initial value
//     const [importAddress, setImportAddress] = useState('');
//     const [status, setStatus] = useState('');
//     const [createdBy, setCreatedBy] = useState('');
//     const [createdDate, setCreatedDate] = useState(''); // Use appropriate initial value
//     const [editedBy, setEditedBy] = useState('');
//     const [editedDate, setEditedDate] = useState(null); // Use appropriate initial value
//     const [approvedBy, setApprovedBy] = useState('');
//     const [approvedDate, setApprovedDate] = useState(null); // Use appropriate initial value
//     const [FlightS, setFlightS] = useState([]);
//     const [uomWeight, setuomWeight] = useState('KGS');
//     const [parties, setParties] = useState([]);
//     const [selectedParty, setSelectedParty] = useState(null);
//     const [partyName, setparty_Name] = useState('');
//     const [consoles, setConsoles] = useState([]);
//     const [pcktype, setPcktype] = useState([]);
//     const [ImportsofMAWB, setImportsofMAWB] = useState([]);
//     const [nsdl_Status, setNSDL_Status] = useState('');
//     const [dgdc_Status, seTDGDC_Status] = useState('');
//     const [CreatedUser, setCreatedUser] = useState('');
//     const [approvedUser, setApprovedUser] = useState('');
//     const [closeStatus, setcloseStatus] = useState('N');
//     const [holdStatus, setHoldStatus] = useState('N');
//     const [holdDate, setHoldDate] = useState('0000-00-00'); // You can initialize with the appropriate default date
//     const [holdBy, setHoldBy] = useState('');
//     const [hpStatus, setHpStatus] = useState('N');
//     const [hpWeight, setHpWeight] = useState(0.000);
//     const [pcStatus, setPcStatus] = useState('N');
//     const [scStatus, setScStatus] = useState('N');
//     const [cancelStatus, setCancelStatus] = useState('N');
//     const [cancelRemarks, setCancelRemarks] = useState('');
//     const [imposePenaltyAmount, setImposePenaltyAmount] = useState(0.000);
//     const [imposePenaltyRemarks, setImposePenaltyRemarks] = useState('');
//     const [handedOverRepresentativeId, sethandedOverRepresentativeId] = useState('');
//     const [handedOverPartyId, sethandedOverPartyId] = useState('');
//     const [handedOverToType, sethandedOverToType] = useState('');
//     const [reasonforOverride, setReasonforOverride] = useState('');
//     const [chaName, setchaName] = useState('');

//     const [nsdlStatusDocs, setnsdlStatusDocs] = useState('');
//     const [niptStatus, setniptStatus] = useState("N");
//     const [importernameOnParcel, setimporternameOnParcel] = useState('');
//     const [qrcodeUrl, setqrcodeUrl] = useState('');
//     const [doNumber, setdoNumber] = useState('');
//     const [doDate, setdoDate] = useState('');
//     const [airlineCode, setAirlineCode] = useState('');
//     const [outDate, setoutDate] = useState('');

//     const [forwardedStatus, setForwardedStatus] = useState('N');
//     const [noc, setNoc] = useState(0);
//     const [dgdcSeepzInScan, setDgdcSeepzInScan] = useState(0);
//     const [dgdcSeepzOutScan, setDgdcSeepzOutScan] = useState(0);
//     const [dgdcCargoInScan, setDgdcCargoInScan] = useState(0);
//     const [dgdcCargoOutScan, setDgdcCargoOutScan] = useState(0);



//     const [niptCustomOfficerName, setniptCustomOfficerName] = useState('');
//     const [niptCustomsOfficerDesignation, setniptCustomsOfficerDesignation] = useState('');
//     const [niptDeputedFromDestination, setniptDeputedFromDestination] = useState('');
//     const [niptDeputedToDestination, setniptDeputedToDestination] = useState('');
//     const [niptDateOfEscort, setniptDateOfEscort] = useState('');
//     const [niptApproverName, setniptApproverName] = useState('');
//     const [niptApproverDesignation, setniptApproverDesignation] = useState('');
//     const [niptApproverDate, setniptApproverDate] = useState('');
//     const [wrongDepositFilePath, setwrongDepositFilePath] = useState('');
//     const [wrongDepositwrongDepositRemarks, setwrongDepositwrongDepositRemarks] = useState('');
//     const [wrongDepositStatus, setwrongDepositStatus] = useState('');
//     const [detentionReceiptNo, setdetentionReceiptNo] = useState('');
//     const [mopStatus, setMopStatus] = useState('N');


//     const [cartingAgent, setcartingAgent] = useState('');
//     const [partyRepresentativeId, setpartyRepresentativeId] = useState('');




//     const {
//         userId,
//         username,
//         branchId,
//         companyid,
//         logintype,
//         logintypeid,
//         userType

//     } = useContext(AuthContext);

//     const navigate = useNavigate();

//     const location = useLocation();
//     const transId2 = location.state?.transId2;
//     const mawb2 = location.state?.mawb2;
//     const hawb2 = location.state?.hawb2;
//     const sir2 = location.state?.sir2;
//     const transId3 = location.state?.transId3;
//     const mawb3 = location.state?.mawb3;
//     const hawb3 = location.state?.hawb3;
//     const sir3 = location.state?.sir3;
//     const searchCriteria = location.state?.searchCriteria;


//     const currentPage = location.state?.currentPage;

//     const Handleback = () => {
//         navigate(`/parent/import`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } })
//     };

//     useEffect(() => {
//         if (mawb2 && hawb2) {
//             getByMAWBnoAndHAwb(companyid, branchId, transId2, mawb2, hawb2, sir2);
//             getImportsOfMAWB(companyid, branchId, mawb2);
//         };
//         if (mawb3 && hawb3) {
//             getByMAWBnoAndHAwb(companyid, branchId, transId3, mawb3, hawb3, sir3);
//         };
//     }, [])

//     const getImportsOfMAWB = (compId, branchId, MAWBNo) => {
//         Rate_Chart_Service.getByMAWBNo(compId, branchId, MAWBNo).then((res) => {
//             setImportsofMAWB(res.data);
//         })
//     }




//     const handleFlightNumberChange = (event) => {
//         const enteredFlightNumber = event.target.value;

//         // Check if the length is greater than or equal to 2
//         if (enteredFlightNumber.length >= 2) {
//             // Extract the first two letters
//             const firstTwoLetters = enteredFlightNumber.substring(0, 2).toUpperCase();
//             //   console.log("firstTwoLetters "+ firstTwoLetters);
//             // Filter airlines based on AirlineShortName
//             const matchingAirline = FlightS.find(airline => airline.airlineShortName === firstTwoLetters);
//             //  console.log("Matched "+ matchingAirline);
//             // Set airline code and name if a match is found
//             if (matchingAirline) {
//                 setAirlineCode(matchingAirline.airlineCode);
//                 setAirlineName(matchingAirline.airlineName);
//             } else {
//                 // Handle the case where no matching airline is found
//                 // You can set default values or handle it according to your requirements
//                 setAirlineCode('');
//                 setAirlineName('');
//             }
//         }


//         if (!enteredFlightNumber) {
//             setAirlineCode('');
//             setAirlineName('');
//         }
//         // Update the flight number in the state
//         setFlightNo(enteredFlightNumber);
//         // console.log("flight "+ enteredFlightNumber);
//         // console.log("airline code  "+ airlineCode);
//         // console.log("airline Name "+ airlineName);

//     };


//     // new method

//     // const getAirlineNameByShortName = async (shortname) => {
//     //     try {
//     //         const response = await Rate_Chart_Service.getAirlineNameByShortName(companyid, branchId, shortname);
//     //         setAirlineName(response.data.airlineName);
//     //         setAirlineCode(response.data.airlineCode);
//     //     } catch (error) {
//     //         // Handle the error here
//     //         console.error('An error occurred:', error);
//     //         // You can also set an error state or show an error message to the user.
//     //     }
//     // };
//     // useEffect(() => {
//     //     if (flightNo && flightNo.length === 2) {
//     //         getAirlineNameByShortName(flightNo);
//     //     }
//     //     if (!flightNo) {
//     //         setAirlineName('');
//     //         setAirlineCode('');
//     //     }
//     // }, [flightNo]);



//     // const handleValidation = () => {
//     //     let formIsValid = true;
//     //     const newErrors = {};

//     //     // Validate serviceShortDescription
//     //     if(pcStatus !=='Y')
//     //     {


//     //     if (mawb.length !== 11) {
//     //         formIsValid = false;
//     //         newErrors.mawb = 'mawb should be an 11-digit';
//     //     }
//     // }

//     //     if (!mawb) {
//     //         formIsValid = false;
//     //         newErrors['mawb'] = 'mawb is required.';

//     //     }

//     //     // Validate serviceType
//     //     if (!flightNo) {
//     //         formIsValid = false;
//     //         newErrors['flightNo'] = 'FlightNo is required.';

//     //     }

//     //     if (!igmNo) {
//     //         formIsValid = false;
//     //         newErrors['igmNo'] = 'IgmNo is required.';

//     //     }
//     //     if (!consoleName) {
//     //         formIsValid = false;
//     //         newErrors['consoleName'] = 'Console is required.';

//     //     }
//     //     if (!grossWeight || grossWeight <= 0) {
//     //         formIsValid = false;
//     //         newErrors['grossWeight'] = 'Gross weight must be greater than 0.';
//     //     }

//     //     if (!importerId) {
//     //         formIsValid = false;
//     //         newErrors['importerId'] = 'importerId is required.';

//     //     }
//     //     if (!partyName) {
//     //         formIsValid = false;
//     //         newErrors['partyName'] = 'Party is required.';

//     //     }
//     //     // if (!hawb) {
//     //     //     formIsValid = false;
//     //     //     newErrors['hawb'] = 'Hawb is required.';

//     //     // }
//     //     if (!packageContentType) {
//     //         formIsValid = false;
//     //         newErrors['packageContentType'] = 'PackageContentType is required.';

//     //     }

//     //     // console.warn(newErrors);
//     //     setErrors(newErrors);
//     //     return formIsValid;
//     // };



//     const handleValidation = () => {
//         let formIsValid = true;
//         const newErrors = {};

//         // Validate serviceShortDescription
//         if (!(pcStatus === 'Y' || niptStatus === 'Y')) {


//             if (mawb.length !== 11) {
//                 formIsValid = false;
//                 newErrors.mawb = 'mawb should be an 11-digit';
//             }
//         }

//         if (!mawb) {
//             formIsValid = false;
//             newErrors['mawb'] = 'mawb is required.';

//         }

//         // Validate serviceType
//         if (!(pcStatus === 'Y' || niptStatus === 'Y')) {

//             if (!flightNo) {
//                 formIsValid = false;
//                 newErrors['flightNo'] = 'FlightNo is required.';

//             }

//             if (!igmNo) {
//                 formIsValid = false;
//                 newErrors['igmNo'] = 'IgmNo is required.';

//             }
//         }
//         if (!consoleName) {
//             formIsValid = false;
//             newErrors['consoleName'] = 'Console is required.';

//         }

//         if (!nop || nop <= 0) {
//             formIsValid = false;
//             newErrors['nop'] = 'please enter nop';
//         }


//         if ((pcStatus !== 'Y') && (!grossWeight || grossWeight <= 0)) {
//             formIsValid = false;
//             newErrors['grossWeight'] = 'Gross weight must be greater than 0.';
//         }



//         // if (!grossWeight || grossWeight <= 0) {
//         //     formIsValid = false;
//         //     newErrors['grossWeight'] = 'Gross weight must be greater than 0.';
//         // }

//         if (!importerId) {
//             formIsValid = false;
//             newErrors['importerId'] = 'importerId is required.';

//         }
//         if (!partyName) {
//             formIsValid = false;
//             newErrors['partyName'] = 'Party is required.';

//         }
//         if (!(pcStatus === 'Y' || niptStatus === 'Y')) {

//             if (!packageContentType) {
//                 formIsValid = false;
//                 newErrors['packageContentType'] = 'PackageContentType is required.';

//             }
//         }
//         // console.warn(newErrors);
//         setErrors(newErrors);
//         return formIsValid;
//     };

//     const [ConsoleNameById, setConsoleNameById] = useState();
//     const getConsoleNameById = async (id) => {

//         Rate_Chart_Service.getUsernameByexternalUserId(companyid, branchId, id)
//             .then((res) => {
//                 setConsoleNameById(res.data);


//             });

//     };




//     const [chas, setChas] = useState([]);
//     const [chanameByCode, setchanameByCode] = useState(null);
//     // const getChaname = async (code) => {
//     //     const name = await Rate_Chart_Service.getUsernameByexternalUserId(companyid, branchId, code);

//     //     console.log(name);
//     //     console.log(name.data);
//     //     setchanameByCode(name.data);
//     // }


//     const findCHAs = async () => {
//         const partyOptions = await findExternalPartyByType(companyid, branchId, 'CHA')
//         setChas(partyOptions);

//     };


//     const handleCHAChange = selectedOption => {
//         setchanameByCode(selectedOption);
//         setchaName(selectedOption ? selectedOption.value : '');
//     };





//     const getByMAWBnoAndHAwb = async (compId, branchID, transId, mawb, hawb, sirNo) => {

//         Rate_Chart_Service.GetByMAWBandHAWB(compId, branchID, transId, mawb, hawb, sirNo).then((res) => {
//             setMawb(res.data.mawb);
//             setHawb(res.data.hawb);
//             setIgmNo(res.data.igmNo);
//             setSirNo(res.data.sirNo);
//             setSirDate(res.data.sirDate);
//             setAirlineName(res.data.airlineName);
//             setFlightNo(res.data.flightNo);

//             setIgmDate(new Date(res.data.igmDate));
//             setBeDate(new Date(res.data.beDate));
//             setFlightDate(new Date(res.data.flightDate));

//             setCountryOrigin(res.data.countryOrigin);
//             setPortOrigin(res.data.portOrigin);
//             setImporterId(res.data.importerId);
//             setcloseStatus(res.data.closeStatus);

//             getPartyByID(companyid, branchId, res.data.importerId,res.data.chaName);

//             setIec(res.data.iec);
//             setSezEntityId(res.data.sezEntityId);
//             setconsoleName(res.data.consoleName);

//             if (res.data.consoleName !== null && res.data.consoleName !== '') {
//                 getConsoleNameById(res.data.consoleName);
//             }



//             setPackageContentType(res.data.packageContentType);
//             setNop(res.data.nop);
//             setParcelType(res.data.parcelType);
//             setImportRemarks(res.data.importRemarks);
//             setDescriptionOfGoods(res.data.descriptionOfGoods);
//             setChaCde(res.data.chaCde);
//             setAssessableValue(res.data.assessableValue);
//             setGrossWeight(res.data.grossWeight);
//             setBeRequestId(res.data.beRequestId);
//             setBeNo(res.data.beNo);
//             // sethppackageno(res.data.hppackageno);
//             setStatus(res.data.status);
//             setTpNo(res.data.tpNo);
//             setPctmNo(res.data.pctmNo);

//             setHoldStatus(res.data.holdStatus);
//             setHoldDate(res.data.holdDate);
//             setHoldBy(res.data.holdBy);
//             setHpStatus(res.data.hpStatus);
//             // setHpWeight(res.data.hpWeight);
//             setPcStatus(res.data.pcStatus);
//             setScStatus(res.data.scStatus);
//             setCancelStatus(res.data.cancelStatus);
//             setCancelRemarks(res.data.cancelRemarks);
//             setImposePenaltyAmount(res.data.imposePenaltyAmount);
//             setImposePenaltyRemarks(res.data.imposePenaltyRemarks);


//             setnsdlStatusDocs(res.data.nsdlStatusDocs);
//             setReasonforOverride(res.data.reasonforOverride);

//             // getApprovedUser(res.data.approvedBy, companyid, branchID);


//             setApprovedBy(res.data.approvedBy);



//             // getCreatedUser(res.data.createdBy, companyid, branchID);




//             setTpDate(res.data.tpDate);
//             setEditedBy(res.data.editedBy);
//             setEditedDate(res.data.editedDate);
//             setCreatedDate(res.data.createdDate);
//             setApprovedDate(res.data.approvedDate);
//             setCreatedBy(res.data.createdBy);
//             setuomWeight(res.data.uomWeight);
//             setImportAddress(res.data.importAddress);
//             setUomPackages(res.data.uomPackages);
//             setcompanyId(res.data.companyId);
//             setBranchId(res.data.DatebranchId);
//             setImpTransId(res.data.impTransId);
//             setImpTransDate(res.data.impTransDate);
//             setNSDL_Status(res.data.nsdl_Status);
//             seTDGDC_Status(res.data.dgdc_Status);
//             setoutDate(res.data.outDate);


//             sethandedOverRepresentativeId(res.data.handedOverRepresentativeId);
//             sethandedOverPartyId(res.data.handedOverPartyId);
//             sethandedOverToType(res.data.handedOverToType);
//             setniptStatus(res.data.niptStatus);
//             setimporternameOnParcel(res.data.importernameOnParcel);
//             setqrcodeUrl(res.data.qrcodeUrl);
//             setdoNumber(res.data.doNumber);
//             setdoDate(res.data.doDate);
//             setchaName(res.data.chaName);

//             // if (res.data.chaName !== null && res.data.chaName !== '') {
//             //     getChaname(res.data.chaName);
//             // }

//             setAirlineCode(res.data.airlineCode)

//             setniptApproverDate(res.data.niptApproverDate);
//             setniptApproverDesignation(res.data.niptApproverDesignation);
//             setniptApproverName(res.data.niptApproverName);
//             setniptCustomOfficerName(res.data.niptCustomOfficerName);
//             setniptCustomsOfficerDesignation(res.data.niptCustomsOfficerDesignation);
//             setniptDateOfEscort(res.data.niptDateOfEscort);
//             setniptDeputedToDestination(res.data.niptDeputedToDestination);
//             setniptDeputedFromDestination(res.data.niptDeputedFromDestination);
//             setwrongDepositFilePath(res.data.wrongDepositFilePath);
//             setwrongDepositwrongDepositRemarks(res.data.wrongDepositwrongDepositRemarks);
//             setwrongDepositStatus(res.data.wrongDepositStatus);
//             setdetentionReceiptNo(res.data.detentionReceiptNo);
//             setForwardedStatus(res.data.forwardedStatus);
//             setNoc(res.data.noc);
//             setDgdcSeepzInScan(res.data.dgdcSeepzInScan);
//             setDgdcSeepzOutScan(res.data.dgdcSeepzOutScan);
//             setDgdcCargoInScan(res.data.dgdcCargoInScan)
//             setDgdcCargoOutScan(res.data.dgdcCargoOutScan);
//             setMopStatus(res.data.mopStatus);

//             setcartingAgent(res.data.cartingAgent);
//             setpartyRepresentativeId(res.data.partyRepresentativeId);

//         })
//     };







//     const makefieldEmpty = () => {

//         setHawb('');
//         setSirNo('');
//         setPackageContentType('LAB GROWN DIAMOND(LGD)');
//         setNop(1);
//         setParcelType('');
//         setImportRemarks('');
//         setDescriptionOfGoods('LGD');
//         setChaCde('');
//         setAssessableValue('');
//         setBeRequestId('');
//         setBeNo('');
//         setBeDate('');
//         setTpDate('');
//         setStatus('');
//         setApprovedBy('');
//         setCreatedBy('');
//         setuomWeight('');
//         setImportAddress('');
//         setUomPackages('');
//         setTpNo('');
//         setPctmNo('');
//         setErrors('');
//         setAssessableValue('');
//         setGrossWeight('');
//         setBeRequestId('');
//         setImpTransId('');
//         setImpTransDate('');
//         setApprovedBy('');
//         setEditedBy('');
//         setEditedDate('');
//         setCreatedDate('');
//         setApprovedDate('');
//         setCreatedBy('');
//         setuomWeight('KGS');
//         setoutDate('');
//         setImportAddress('');
//         setNSDL_Status('');
//         seTDGDC_Status('');
//         setcompanyId('');
//         setCreatedUser('');
//         setApprovedUser('');
//         sethandedOverPartyId('');
//         sethandedOverRepresentativeId('');
//         sethandedOverToType('');
//         setniptStatus('');
//         setqrcodeUrl('');
//         setimporternameOnParcel('');
//         setdoNumber('');
//         setdoDate('');
//         setImporterId('');
//         setparty_Name('');
//         setSezEntityId('');
//         setIec('');
//         setchaName('');
//         setniptApproverDate('');
//         setniptApproverDesignation('');
//         setniptApproverName('');
//         setniptCustomOfficerName('');
//         setniptCustomsOfficerDesignation('');
//         setniptDateOfEscort('');
//         setniptDeputedToDestination('');
//         setniptDeputedFromDestination('');
//         setwrongDepositFilePath('');
//         setwrongDepositwrongDepositRemarks('');
//         setwrongDepositStatus('');
//         setdetentionReceiptNo('');
//         setForwardedStatus("N");
//         setNoc(0);
//         setDgdcSeepzInScan(0);
//         setDgdcSeepzOutScan(0);
//         setDgdcCargoInScan(0)
//         setDgdcCargoOutScan(0);
//         setMopStatus('N');

//         setcartingAgent('');
//         setpartyRepresentativeId('');
//     };


//     useEffect(() => {

//         const foundParty = chas.find(chas => chas.value === chaName);
//         if (foundParty) {
//             setchanameByCode(foundParty);
//         } else {
//             setchanameByCode(null);
//         }

//     }, [chas, chaName]);


//     useEffect(() => {
//         // findAirlines();
//         findParties();
//         findPCKTYPE();
//         findConsoles();
//         findCHAs();
//         findAirlines();
//     }, []);

//     const findExternalPartyByType = async (compid, branchid, Type) => {

//         const partyResponse = await Rate_Chart_Service.getExternalUserByTypeForImport(compid, branchid, Type);
//         const partyOptions = partyResponse.data.map(externalUser => ({
//             value: externalUser.externaluserId,
//             label: externalUser.userName
//         }));
//         return partyOptions;

//     };
//     const findConsoles = async () => {
//         const partyOptions = await findExternalPartyByType(companyid, branchId, 'console')
//         setConsoles(partyOptions);

//     };

//     const findPCKTYPE = async () => {
//         const PCKTYPEResponse = await Rate_Chart_Service.getjarsByJarId('J00008', companyid, branchId);
//         const partyOptions = PCKTYPEResponse.data.map(jar => ({
//             value: jar.jarId,
//             label: jar.jarDtlDesc
//         }));
//         setPcktype(partyOptions);
//     };


//     const handleConsoleChange = selectedOption => {


//         setconsoleName(selectedOption ? selectedOption.value : '');
//         setConsoleNameById(selectedOption ? selectedOption.label : '')

//     };

//     const handlepckgtype = selectedOption => {

//         setPackageContentType(selectedOption ? selectedOption.label : '');
//         if (selectedOption) {
//             setPackageContentType(selectedOption ? selectedOption.label : '');

//             // Extract text within parentheses from packageContentType
//             const extractedValue = extractValueWithinParentheses(selectedOption ? selectedOption.label : '');
//             // console.log("extractedValue "+extractedValue);
//             setDescriptionOfGoods(extractedValue);
//         } else {
//             setPackageContentType('');
//             setDescriptionOfGoods(''); // Reset descriptionOfGoods if no package content type is selected
//         }


//     };
//     // Function to extract text within parentheses from package content type
//     const extractValueWithinParentheses = (text) => {
//         const match = text.match(/\(([^)]+)\)/); // Using regex to find text within parentheses
//         return match ? match[1] : ''; // Extracted text or empty string if not found
//     };



//     const findParties = async () => {
//         const partyResponse = await Rate_Chart_Service.getAllParties(companyid, branchId);
//         const partyOptions = partyResponse.data.map(party => ({
//             value: party.partyId,
//             label: party.partyName
//         }));
//         setParties(partyOptions);

//     };

//     const findAirlines = async () => {
//         const AirResponse = await Rate_Chart_Service.getAllairline(companyid, branchId);
//         // const AirOptions = AirResponse.data.map(party => ({
//         //     value: party.airlineCode,
//         //     label: party.flightNo
//         // }));
//         setFlightS(AirResponse.data);
//     };



//     const handlePartyChange = async (selectedOption, { action }) => {

//         if (action === 'clear') {
//             setparty_Name('');
//             setIec('');
//             setSezEntityId('');
//             setImporterId('');
//             setimporternameOnParcel('');
//         }
//         else {
//             setSelectedParty(selectedOption);
//             setimporternameOnParcel(selectedOption ? selectedOption.label : '');
//             // setparty_Name(selectedOption ? selectedOption.label : '');
//             setImporterId(selectedOption ? selectedOption.value : '');
//             getPartyByID(companyid, branchId, selectedOption ? selectedOption.value : '')
//         }
//     };

//     const customFilterOption = (candidate, input) => {
//         const inputLower = input.toLowerCase();
//         const labelLower = candidate.label.toLowerCase();
//         return candidate.data.__isNew__ || labelLower.startsWith(inputLower);
//     };

//     const handleFlightChange = (selectedOption, { action }) => {

//         if (action === 'clear') {
//             setFlightNo('');
//             setAirlineCode('')
//             setAirlineName('');
//         }
//         else {
//             setFlightNo(selectedOption ? selectedOption.label : '');
//             setAirlineCode(selectedOption ? selectedOption.value : '')
//             getSingleFlightlist(selectedOption ? selectedOption.value : '');
//         }
//     };


//     const getSingleFlightlist = async (airlineCode) => {

//         Rate_Chart_Service.getAirlineNameByCode(companyid, branchId, airlineCode)
//             .then((response) => {

//                 setAirlineName(response.data);

//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };





//     const addImport = async () => {
//         setIsSubmitting(true);

//         try {
//             const res = await Rate_Chart_Service.addImport(companyid, branchId, userId, importData);

//             // Continue with the import processing
//             getImportsOfMAWB(res.data.companyId, res.data.branchId, res.data.mawb);
//             getByMAWBnoAndHAwb(res.data.companyId, res.data.branchId, res.data.impTransId, res.data.mawb, res.data.hawb, res.data.sirNo);

//             const toastContent = `Import with SIR No  <strong> ${res.data.sirNo} </strong> Added Successfully!`;
//             const contentWidth = toastContent.length * 10;
//             toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 3000,
//                 style: { width: `${contentWidth}px` },
//             });
//         } catch (error) {
//             // Handle the error response, e.g., show an error toast
//             const errorMessage = error.response ? error.response.data : "An error occurred during import.";
//             const contentWidth = errorMessage.length * 12;
//             toast.error(errorMessage, {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 2000,
//                 style: { width: `${contentWidth}px` },
//             });
//         } finally {
//             // This block will be executed regardless of success or failure
//             setIsSubmitting(false);
//         }
//     };


//     // const updateModify = () => {

//     //     Rate_Chart_Service.ModifyupdateImport(companyid, branchId, userId, importData).then((res) => {
//     //         console.log(res.data);
//     //         getImportsOfMAWB(res.data.companyId, res.data.branchId, res.data.mawb);
//     //         getByMAWBnoAndHAwb(res.data.companyId, res.data.branchId, res.data.impTransId, res.data.mawb, res.data.hawb, res.data.sirNo);
//     //         toast.success('Import Updated Successfully !', {
//     //             position: toast.POSITION.TOP_CENTER,
//     //             autoClose: 600,
//     //         });
//     //     })
//     // };

//     const updateModify = () => {

//         Rate_Chart_Service.ModifyupdateImport(companyid, branchId, userId, importData).then((res) => {

//             // console.log("*******Data For Modify************ ");

//             if (res.status === 200) {
//                 console.log(res.data);
//                 getImportsOfMAWB(res.data.companyId, res.data.branchId, res.data.mawb);
//                 getByMAWBnoAndHAwb(res.data.companyId, res.data.branchId, res.data.impTransId, res.data.mawb, res.data.hawb, res.data.sirNo);
//                 toast.success('Import Updated Successfully !', {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 600,
//                 });
//             } else {

//             }
//         }).catch((error) => {


//             const errorMessage = error.response ? error.response.data : "An error occurred during import.";
//             const contentWidth = errorMessage.length * 12;
//             toast.error(errorMessage, {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 2000,
//                 style: { width: `${contentWidth}px` },
//             });

//         });
//     };



//     const getPartyByID = async (companyId, branchId, id3,chaNameReceived) => {
//         try {
//             const res = await Rate_Chart_Service.getPartyByIdNew(companyId, branchId, id3, 'IMP');
//             if (res.data) {
//                 setparty_Name(res.data.partyName);
//                 // setchaName(res.data.status);
//                 setIec(res.data.iecNo);
//                 setSezEntityId(res.data.entityId);

//                 setchaName( chaNameReceived ?  chaNameReceived : res.data.status);

//             } else {
//                 console.log("No data received from getPartyById API");
//             }
//         } catch (error) {
//             console.error("Error fetching party data:", error);
//         }
//     };
//     const getCreatedUser = (id3, companyid, branchid) => {
//         Rate_Chart_Service.getUserbyUserId(id3, companyid, branchid).then((res) => {
//             setCreatedUser(res.data.user_Name);
//             // alert(CreatedUser);
//         })
//     };

//     const getApprovedUser = (id2, companyid, branchid) => {
//         if (id2) {
//             Rate_Chart_Service.getUserbyUserId(id2, companyid, branchid).then((res) => {
//                 setApprovedUser(res.data.user_Name);
//             })
//         };
//     };





//     const DeleteImport = (compaId, branchId, transId, mawbno, hawbno, sir) => {
//         {


//             Swal.fire({
//                 title: 'Are you sure?',
//                 text: "You won't be able to revert this!",
//                 icon: 'warning',
//                 showCancelButton: true,
//                 confirmButtonColor: '#3085d6',
//                 cancelButtonColor: '#d33',
//                 confirmButtonText: 'Yes, delete it!'
//             }).then((result) => {
//                 if (result.isConfirmed) {
//                     Rate_Chart_Service.deleteimportofmawb(compaId, branchId, transId, mawbno, hawbno, sir).then((res) => {
//                         // Swal.fire('Service Deleted Successfully', 'You clicked the button', 'success');

//                         getImportsOfMAWB(compaId, branchId, mawbno);

//                         // console.log(res.data)
//                         makefieldEmpty();

//                     }
//                     )

//                     toast.error('Service Deleted Successfully !', {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 700,
//                     });
//                 }
//             })




//         }
//     };
//     const [isSubmitting, setIsSubmitting] = useState(false);

//     const updateImportAll = () => {


//         let totalNops = 0;
//         let totalWeight = 0;

//         // Iterate over the importData to calculate totals
//         ImportsofMAWB.forEach((importItem) => {
//             totalNops += importItem.nop;
//             totalWeight += importItem.grossWeight;
//         });


//         Swal.fire({
//             title: 'Are you sure?',
//             html: `<b>Total Packages:  ${totalNops} </b> ,<b>Total Master Weight: ${totalWeight.toFixed(3)} KGS</b> </br> You won't be able to add more imports!`,
//             icon: 'warning',
//             showCancelButton: true,
//             customClass: {
//                 icon: 'icon-smaller' // Apply the custom class to the icon
//             },
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Yes, close it!'
//         }).then((result) => {
//             if (result.isConfirmed) {

//                 Rate_Chart_Service.updateImport(companyid, branchId, userId, importData).then((resList) => {
//                     // Iterate over the list of Import objects returned by the updateImport method
//                     resList.data.forEach((importItem) => {
//                         // Perform actions for each Import item

//                         // Call the getImportsOfMAWB method
//                         getImportsOfMAWB(importItem.companyId, importItem.branchId, importItem.mawb);

//                         // Call the getByMAWBnoAndHAwb method
//                         getByMAWBnoAndHAwb(importItem.companyId, importItem.branchId, importItem.impTransId, importItem.mawb, importItem.hawb, importItem.sirNo);


//                     });
//                     toast.error('Master Closed Successfully !', {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 700,
//                     });
//                 });

//             }
//         })




//     };



//     const SaveorSubmit = () => {
//         // alert("On the Way")
//         const isFormValid = handleValidation();
//         if (isFormValid) {
//             if (!(pcStatus === 'Y' || niptStatus === 'Y')) {
//                 // Continue with the condition related to airlineCode only if pcStatus and niptStatus are not 'Y'
//                 if (airlineCode) {
//                     // alert("in")
//                     if (status) {
//                         if (mawb2 && hawb2) {
//                             updateModify();
//                         }
//                         if (!mawb2 && !hawb2) {
//                             updateModify();
//                         }
//                     } else {
//                         addImport();
//                     }
//                 } else {
//                     const errorMessage = "Your Entered Flight Number's Airline is not Present In Airline Master! Please add Airline in Airline Master!!!";
//                     const contentWidth = errorMessage.length * 5;

//                     toast.error(errorMessage, {
//                         position: toast.POSITION.CENTER,
//                         autoClose: 6000,
//                         style: { width: `${contentWidth}px` },
//                     });
//                 }
//             } else {
//                 if (status) {
//                     if (mawb2 && hawb2) {
//                         updateModify();
//                     }
//                     if (!mawb2 && !hawb2) {
//                         updateModify();
//                     }
//                 }

//                 else {

//                     addImport();
//                 }

//             }
//         } else {
//             toast.error('Oops something went wrong!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 700,
//             });
//         }
//     };






//     // const SaveorSubmit = () => {
//     //     // alert("On the Way")
//     //     const isFormValid = handleValidation();
//     //     if (isFormValid) {



//     //         if(airlineCode)
//     //         {

//     //         // alert("in")

//     //         if (status) {
//     //             if (mawb2 && hawb2) {
//     //                 updateModify();
//     //             }
//     //             if (!mawb2 && !hawb2) {
//     //                 updateModify();
//     //             }
//     //         }

//     //         else {

//     //             addImport();
//     //         }
//     //     }
//     //     else {

//     //         const errorMessage = "Your Entered Flight Number's Airline is not Present In Airline Master!Please add Airline in Airline Master!!!";
//     //         const contentWidth = errorMessage.length * 8;

//     //         toast.error(errorMessage, {
//     //             position: toast.POSITION.CENTER,
//     //             autoClose: 6000,
//     //             style: { width: `${contentWidth}px` },
//     //         });
//     //     }
//     //     }
//     //     else {

//     //         toast.error('Oops something went wrong !', {
//     //             position: toast.POSITION.TOP_CENTER,
//     //             autoClose: 700,
//     //         });
//     //     }
//     // };

//     const importData = {
//         companyId, branchId,
//         uomWeight, impTransId, impTransDate, mawb, hawb, igmNo, igmDate, sirNo, sirDate, pctmNo, tpNo, tpDate, airlineName,
//         flightNo, flightDate, countryOrigin, portOrigin, importerId, iec, sezEntityId, consoleName, packageContentType, parcelType, niptStatus, importernameOnParcel, qrcodeUrl,
//         uomPackages, nop, importRemarks, descriptionOfGoods, chaCde, assessableValue, grossWeight, beRequestId, beNo, beDate, handedOverToType, handedOverPartyId, handedOverRepresentativeId,
//         importAddress, status, createdBy, createdDate, editedBy, editedDate, approvedBy, approvedDate, dgdc_Status, nsdl_Status, closeStatus, reasonforOverride, nsdlStatusDocs,
//         holdStatus, holdDate, holdBy, hpStatus, pcStatus, scStatus, cancelStatus, cancelRemarks, imposePenaltyAmount, imposePenaltyRemarks, doNumber, doDate, chaName, airlineCode, outDate,
//         niptCustomOfficerName, niptCustomsOfficerDesignation, niptDeputedFromDestination, mopStatus,
//         niptDeputedToDestination, niptDateOfEscort, niptApproverName, niptApproverDesignation, niptApproverDate, wrongDepositFilePath, wrongDepositwrongDepositRemarks, wrongDepositStatus, detentionReceiptNo,
//         forwardedStatus, noc, dgdcSeepzInScan, dgdcSeepzOutScan, dgdcCargoInScan, dgdcCargoOutScan, cartingAgent, partyRepresentativeId
//     };


//     function handleKeyPress(event) {
//         if (event.key === 'Enter') {

//             handleDateChange(new Date()); // Call the function to set the current date
//         }
//     }




//     const handleDateChange = (date1) => {
//         setIgmDate(date1);
//         setFlightDate(date1);
//     };

//     const handleDateChangeFlight = (date2) => {
//         setFlightDate(date2);
//     };
//     const handleDateChangeBE = (date) => {
//         setBeDate(date);
//     };

//     function handleInputChange(e) {
//         const inputValue = e.target.value;
//         const numericInput = inputValue.replace(/[^0-9.]/g, '');
//         const parts = numericInput.split('.');
//         const integerPart = parts[0];
//         let decimalPart = parts[1];

//         // Limit decimal places if needed
//         if (decimalPart !== undefined) {
//             decimalPart = `.${decimalPart.slice(0, 3)}`;
//         }

//         const sanitizedInput = decimalPart !== undefined ? `${integerPart}${decimalPart}` : integerPart;
//         setGrossWeight(sanitizedInput);
//     };

//     function handleNopChange(e) {
//         const inputValue = e.target.value;
//         const numericInput = inputValue.replace(/[^0-9]/g, '');
//         setNop(numericInput);
//     };


//     return (
//         <div className="Container" >
//             <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                 icon={faPlaneUp}
//                 style={{
//                     marginRight: '8px',
//                     color: 'black', // Set the color to golden
//                 }}
//             />Add New Import</h5>


//             <Card style={{ backgroundColor: "#F8F8F8" }}>
//                 <CardBody>
//                     <Row>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">MAWB No.</Label>*
//                                 <Input
//                                     type="text"
//                                     name="mawb"
//                                     className="form-control inputField"
//                                     id={(mawb3 && hawb3) ? 'service' : 'mawb'}
//                                     readOnly={(mawb3 && hawb3)}
//                                     value={mawb}
//                                     maxLength={16} // Add this line to set the maximum length
//                                     style={{ height: '40px', borderColor: errors.mawb ? '#f52b2b' : '' }}
//                                     onChange={(e) => setMawb(e.target.value)}

//                                 />
//                                 {errors.mawb && (
//                                     <div className="error-message">
//                                         {errors.mawb}
//                                     </div>
//                                 )}
//                             </FormGroup>


//                         </Col>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">IGM No.</Label>*
//                                 <Input
//                                     type="text" name="igmNo"
//                                     className="form-control"
//                                     value={igmNo}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                     maxLength={20}
//                                     style={{ borderColor: errors.igmNo ? '#f52b2b' : '' }}
//                                     onChange={(e) => setIgmNo(e.target.value)}
//                                     onKeyDown={(e) => {
//                                         if (e.key === ' ' || e.keyCode === 32) {
//                                             e.preventDefault();
//                                         }
//                                     }}
//                                 />
//                                 {errors.igmNo && (
//                                     <div className="error-message">
//                                         {errors.igmNo}
//                                     </div>
//                                 )}
//                             </FormGroup>

//                         </Col>



//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">IGM Date</Label>
//                                 <div> {/* Wrap in an input group */}
//                                     <DatePicker
//                                         selected={igmDate}
//                                         onKeyDown={handleKeyPress}
//                                         onChange={handleDateChange}
//                                         wrapperClassName="custom-react-datepicker-wrapper"
//                                         dateFormat="dd/MM/yyyy"
//                                         id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                         readOnly={mawb3 && hawb3}
//                                         value={igmDate}
//                                         className="form-control border-right-0 inputField"
//                                         customInput={<input style={{ width: '100%' }} />}
//                                     />

//                                 </div>
//                             </FormGroup>
//                         </Col>

//                         {/* <Col>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Status</Label>
//                                 <Input
//                                     type="text" name="status"
//                                     className="form-control inputField"
//                                     id='service' readOnly
//                                     value={status === "A" ? "Approved" : status === "U" ? "Edit" : status === "N" ? "New" : " "}
//                                     onChange={(e) => setStatus(e.target.value)}
//                                 />
//                             </FormGroup>
//                         </Col> */}
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Airline Name</Label>*
//                                 <Input
//                                     type="text" name="airlineName"
//                                     className="form-control inputField"
//                                     value={airlineName}
//                                     id='service' readOnly
//                                     onChange={(e) => setAirlineName(e.target.value)}
//                                     tabIndex="-1"
//                                 />
//                             </FormGroup>
//                         </Col>
//                     </Row>

//                     {/* 2nd */}

//                     <Row>


//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Flight No.</Label>
//                                 <Input
//                                     type="text" name="flightNo"
//                                     className={errors.flightNo ? 'error-border' : ''}
//                                     value={flightNo}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={(mawb3 && hawb3)}
//                                     onChange={handleFlightNumberChange}
//                                 />

//                                 {errors.flightNo && (
//                                     <div className="error-message">
//                                         {errors.flightNo}
//                                     </div>
//                                 )}
//                             </FormGroup>

//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Flight Date</Label>
//                                 <div> {/* Wrap in an input group */}
//                                     <DatePicker
//                                         selected={flightDate}
//                                         onChange={handleDateChangeFlight}
//                                         wrapperClassName="custom-react-datepicker-wrapper"
//                                         id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                         readOnly={mawb3 && hawb3}
//                                         // minDate={today}
//                                         dateFormat="dd/MM/yyyy"
//                                         value={flightDate} // Set the value from the database
//                                         className="form-control InputField"
//                                         customInput={<input style={{ width: '100%' }} />}
//                                     />

//                                 </div>
//                             </FormGroup>
//                         </Col>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Country of Origin</Label>
//                                 <Input
//                                     type="text" name="countryOrigin"
//                                     className="form-control"
//                                     value={countryOrigin}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     maxLength={30}
//                                     readOnly={mawb3 && hawb3}
//                                     onChange={(e) => setCountryOrigin(e.target.value)}
//                                     onBlur={(e) => {
//                                         const country = e.target.value;
//                                         if (!portOrigin) {
//                                             setPortOrigin(country);
//                                         }
//                                     }}

//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Port of Origin</Label>
//                                 <Input
//                                     type="text" name="portOrigin"
//                                     className="form-control"
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                     maxLength={30}
//                                     value={portOrigin}
//                                     onChange={(e) => setPortOrigin(e.target.value)}
//                                 />
//                             </FormGroup>
//                         </Col>

//                     </Row>



//                     {/* 3rd */}

//                     <Row>

//                         <Col md={3} >
//                             {/* <FormGroup>
//                                 <Label className="forlabel" for="branchId">Select Console</Label>

//                                 <Select
//                                     options={consoles}
//                                     value={{ value: consoleName, label: consoleName }}
//                                     onChange={handleConsoleChange}
//                                     isClearable
//                                     isDisabled={mawb3 && hawb3}
//                                     styles={{
//                                         control: (provided, state) => ({
//                                             ...provided,
//                                             border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                             borderColor: errors.consoleName ? '#f52b2b' : '',
//                                             boxShadow: 'none',
//                                             '&:hover': {
//                                                 border: '1px solid #ccc'
//                                             }
//                                         }),
//                                         indicatorSeparator: () => ({
//                                             display: 'none'
//                                         }),
//                                         dropdownIndicator: () => ({
//                                             display: 'none'
//                                         })
//                                     }}
//                                 />


//                             </FormGroup> */}
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Select Console</Label>

//                                 <Select
//                                     options={consoles}
//                                     value={{ value: ConsoleNameById, label: ConsoleNameById }}
//                                     onChange={handleConsoleChange}
//                                     isClearable
//                                     className={errors.consoleName ? 'error-border' : ''}
//                                     isDisabled={mawb3 && hawb3}
//                                     styles={{
//                                         control: (provided, state) => ({
//                                             ...provided,
//                                             border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                             boxShadow: 'none',
//                                             '&:hover': {
//                                                 border: '1px solid #ccc'
//                                             }
//                                         }),
//                                         indicatorSeparator: () => ({
//                                             display: 'none'
//                                         }),
//                                         dropdownIndicator: () => ({
//                                             display: 'none'
//                                         })
//                                     }}
//                                 />
//                                 {errors.consoleName && (
//                                     <div className="error-message">
//                                         {errors.consoleName}
//                                     </div>
//                                 )}

//                             </FormGroup>
//                         </Col>



//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Select Importer</Label>*
//                                 <Select
//                                     options={parties}
//                                     placeholder="Select a party"
//                                     isClearable
//                                     value={{ value: partyName, label: partyName }}
//                                     onChange={handlePartyChange}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                     filterOption={customFilterOption}
//                                     className={errors.partyName ? 'error-border' : ''}
//                                     isDisabled={mawb3 && hawb3}
//                                     styles={{
//                                         control: (provided, state) => ({
//                                             ...provided,
//                                             border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                             boxShadow: 'none',
//                                             '&:hover': {
//                                                 border: '1px solid #ccc'
//                                             }
//                                         }),
//                                         indicatorSeparator: () => ({
//                                             display: 'none'
//                                         }),
//                                         dropdownIndicator: () => ({
//                                             display: 'none'
//                                         }),
//                                         placeholder: (provided) => ({
//                                             ...provided,
//                                             display: 'flex',
//                                             color: 'gray',
//                                         }),
//                                     }}
//                                 />
//                                 {errors.partyName && (
//                                     <div className="error-message">
//                                         {errors.partyName}
//                                     </div>
//                                 )}

//                             </FormGroup>
//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">IEC No.</Label>
//                                 <Input
//                                     type="text" name="iec"
//                                     className="form-control"
//                                     value={iec}
//                                     onChange={(e) => setIec(e.target.value)}
//                                     id="service"
//                                     readOnly
//                                     tabIndex="-1"
//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">SEZ Entity Id</Label>

//                                 <Input type="text" name="sezEntityId"
//                                     className="form-control"
//                                     value={sezEntityId}
//                                     onChange={(e) => setSezEntityId(e.target.value)}
//                                     id="service"
//                                     readOnly
//                                     tabIndex="-1"
//                                 />
//                             </FormGroup>
//                         </Col>
//                     </Row>


//                     {/* 4th */}


//                     <Row>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Import Remarks</Label>
//                                 <Input
//                                     type="text" name="importRemarks"
//                                     className="form-control"
//                                     value={importRemarks}
//                                     maxLength={150}
//                                     onChange={(e) => setImportRemarks(e.target.value)}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />
//                             </FormGroup>
//                         </Col>


//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Importer Name On Parcel</Label>

//                                 <Input type="text" name="sezEntityId"
//                                     className="form-control"
//                                     value={importernameOnParcel}
//                                     onChange={(e) => setimporternameOnParcel(e.target.value)}
//                                     id={(importerId !== 'NONE' || (mawb3 && hawb3)) ? 'service' : ''}
//                                     readOnly={importerId !== 'NONE' || (mawb3 && hawb3)}
//                                     tabIndex="-1"
//                                 />
//                             </FormGroup>
//                         </Col>


//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">HAWB No.</Label>
//                                 <Input
//                                     type="text" name="hawb"
//                                     className="form-control"
//                                     value={hawb.startsWith('000') ? '' : hawb}
//                                     maxLength={30}
//                                     style={{ borderColor: errors.hawb ? '#f52b2b' : '', }}
//                                     onChange={(e) => setHawb(e.target.value)}
//                                     id={(mawb3 && hawb3) ? 'service' : 'mawb'}
//                                     readOnly={(mawb3 && hawb3)}
//                                     onKeyDown={(e) => {
//                                         if (e.key === ' ' || e.keyCode === 32) {
//                                             e.preventDefault();
//                                         }
//                                     }}
//                                 />

//                             </FormGroup>
//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Package Content Type</Label>
//                                 <Select
//                                     options={pcktype}
//                                     value={{ value: packageContentType, label: packageContentType }}
//                                     onChange={handlepckgtype}
//                                     isClearable
//                                     className={errors.packageContentType ? 'error-border' : ''}
//                                     // id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     // readOnly={mawb3 && hawb3}
//                                     isDisabled={mawb3 && hawb3}
//                                     styles={{
//                                         control: (provided, state) => ({
//                                             ...provided,
//                                             border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                             boxShadow: 'none',
//                                             '&:hover': {
//                                                 border: '1px solid #ccc'
//                                             }
//                                         }),
//                                         indicatorSeparator: () => ({
//                                             display: 'none'
//                                         }),
//                                         dropdownIndicator: () => ({
//                                             display: 'none'
//                                         })
//                                     }}
//                                 />
//                                 {errors.packageContentType && (
//                                     <div className="error-message">
//                                         {errors.packageContentType}
//                                     </div>
//                                 )}
//                             </FormGroup>
//                         </Col>

//                     </Row>


//                     {/* 5Th */}


//                     <Row>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">No. of Packages </Label>

//                                 <Input type="text" name="nop"
//                                     className="form-control"
//                                     value={nop}
//                                     maxLength={3}
//                                     onChange={handleNopChange}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />
//                                 {errors.nop && (
//                                     <div className="error-message">
//                                         {errors.nop}
//                                     </div>
//                                 )}
//                             </FormGroup>
//                         </Col>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">UOM Package Type</Label>
//                                 <Input
//                                     type="text" name="uomPackages"
//                                     className="form-control"
//                                     onChange={(e) => setUomPackages(e.target.value)}
//                                     value={uomPackages}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">CHA_CDE</Label>
//                                 <Input
//                                     type="text" name="chaCde"
//                                     className="form-control"
//                                     onChange={(e) => setChaCde(e.target.value)}
//                                     value={chaCde}
//                                     maxLength={10}
//                                     id='service'
//                                     readOnly
//                                     tabIndex="-1"
//                                 />
//                             </FormGroup>
//                         </Col>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">CHA</Label>
//                                 <Select
//                                     options={chas}
//                                     placeholder="Select cha"
//                                     isClearable
//                                     value={chanameByCode}
//                                     onChange={handleCHAChange}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                     filterOption={customFilterOption}
//                                     // className={errors.chaName ? 'error-border' : ''}
//                                     isDisabled={mawb3 && hawb3}
//                                     styles={{
//                                         control: (provided, state) => ({
//                                             ...provided,
//                                             border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                             boxShadow: 'none',
//                                             '&:hover': {
//                                                 border: '1px solid #ccc'
//                                             }
//                                         }),
//                                         indicatorSeparator: () => ({
//                                             display: 'none'
//                                         }),
//                                         dropdownIndicator: () => ({
//                                             display: 'none'
//                                         }),
//                                         placeholder: (provided) => ({
//                                             ...provided,
//                                             display: 'flex',
//                                             color: 'gray',
//                                         }),
//                                     }}
//                                 />
//                             </FormGroup>
//                         </Col>





//                     </Row>





//                     {/* 6th */}
//                     <Row>



//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Assesable In INR</Label>
//                                 <Input
//                                     type="text" name="assessableValue"
//                                     className="form-control"
//                                     value={assessableValue}
//                                     maxLength={15}
//                                     onChange={(e) => setAssessableValue(e.target.value)}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />
//                             </FormGroup>
//                         </Col>

//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Desc of Goods </Label>

//                                 <Input type="text" name="descriptionOfGoods"
//                                     className="form-control"
//                                     value={descriptionOfGoods}
//                                     maxLength={50}
//                                     onChange={(e) => setDescriptionOfGoods(e.target.value)}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />
//                             </FormGroup>
//                         </Col>



//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Gross Weight </Label>

//                                 {/* <Input type="number" name="grossWeight"
//                                     className={errors.grossWeight ? 'error-border' : ''}
//                                     value={grossWeight}
//                                     maxLength={10}
//                                     onChange={(e) => setGrossWeight(e.target.value)}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 /> */}

//                                 <Input
//                                     type="text"
//                                     name="grossWeight"
//                                     className={`${errors.grossWeight ? 'error-border' : ''}`}
//                                     value={grossWeight}
//                                     maxLength={10}
//                                     onChange={handleInputChange}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />

//                                 {errors.grossWeight && (
//                                     <div className="error-message">
//                                         {errors.grossWeight}
//                                     </div>
//                                 )}
//                             </FormGroup>
//                         </Col>
//                         <Col md={3} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">UOM Weight</Label>
//                                 <Input
//                                     type="text" name="uomWeight"
//                                     className="form-control"
//                                     onChange={(e) => setuomWeight(e.target.value)}
//                                     value={uomWeight}
//                                     maxLength={10}
//                                     id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                     readOnly={mawb3 && hawb3}
//                                 />
//                             </FormGroup>
//                         </Col>

//                     </Row>

//                     {/* 7th */}

//                     {(userType !== "Cargo Official" && userType !== "Cargo Gate" && userType !== "Cargo Custodian") ? (

//                         <Row>

//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">BE Request Id</Label>
//                                     <Input
//                                         type="text" name="beRequestId"
//                                         className="form-control"
//                                         value={beRequestId}
//                                         maxLength={20}
//                                         onChange={(e) => setBeRequestId(e.target.value)}
//                                         id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                         readOnly={mawb3 && hawb3}
//                                     />
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">BE Number </Label>

//                                     <Input type="text" name="beNo"
//                                         className="form-control"
//                                         value={beNo}
//                                         maxLength={18}
//                                         onChange={(e) => setBeNo(e.target.value)}
//                                         id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                         readOnly={mawb3 && hawb3}
//                                         tabIndex="-1"
//                                     />
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">BE Date</Label>
//                                     <div> {/* Wrap in an input group */}
//                                         <DatePicker
//                                             selected={beDate}
//                                             onChange={handleDateChangeBE}
//                                             wrapperClassName="custom-react-datepicker-wrapper"
//                                             // minDate={today}
//                                             dateFormat="dd/MM/yyyy"
//                                             value={beDate} // Set the value from the database
//                                             className="form-control"
//                                             customInput={<input style={{ width: '100%' }} />}
//                                             id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                             readOnly={mawb3 && hawb3}
//                                             tabIndex="-1"
//                                         />
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Importer Address</Label>
//                                     <textarea
//                                         name="importAddress"
//                                         rows="2"
//                                         className="form-control"
//                                         style={{ width: '100%' }}
//                                         maxLength={50}
//                                         value={importAddress}
//                                         onChange={(e) => setImportAddress(e.target.value)}
//                                         id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                         readOnly={mawb3 && hawb3}
//                                         tabIndex="-1"
//                                     ></textarea>
//                                 </FormGroup>
//                             </Col>
//                         </Row>
//                     ) : null}



//                     {((userType !== "Cargo Official" && userType !== "Cargo Gate") && pcStatus === 'Y') ? (
//                         <Row>
//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Detention No.</Label>
//                                     <Input
//                                         type="text" name="portOrigin"
//                                         className="form-control"
//                                         id={mawb3 && hawb3 ? 'service' : 'mawb'}
//                                         readOnly={mawb3 && hawb3}
//                                         maxLength={30}
//                                         value={detentionReceiptNo}
//                                         onChange={(e) => setdetentionReceiptNo(e.target.value)}
//                                         tabIndex="-1"
//                                     />
//                                 </FormGroup>
//                             </Col>
//                         </Row>
//                     ) : null}



//                     <div className="text-center mb-3 mt-2">

//                         {!mawb3 && !hawb3 && (  // Show the "SUBMIT" button if mawb3 and hawb3 are NOT present
//                             <Button
//                                 type="button"

//                                 variant="outline-success"
//                                 style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
//                                 onClick={SaveorSubmit}
//                                 disabled={isSubmitting}
//                             >
//                                 <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
//                                 Submit
//                             </Button>
//                         )}

//                         {!mawb3 && !hawb3 && closeStatus !== 'Y' && (  // Show the "ADD NEW" button if mawb3 and hawb3 are NOT present
//                             <Button
//                                 type="button"

//                                 variant="outline-danger"
//                                 style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
//                                 onClick={makefieldEmpty}
//                             >
//                                 <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
//                                 Clear
//                             </Button>
//                         )}


//                         {closeStatus !== 'Y' && ImportsofMAWB.length > 0 && (
//                             <Button
//                                 type="button"
//                                 variant="outline-danger"
//                                 style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
//                                 onClick={updateImportAll}
//                             >
//                                 <FontAwesomeIcon icon={faClose} style={{ marginRight: '5px' }} />
//                                 Close
//                             </Button>
//                         )}


//                         <Button
//                             type="button"
//                             className="widthbtn"
//                             variant="outline-primary"
//                             style={{ marginLeft: '10px', fontWeight: 'bold' }}
//                             onClick={Handleback}
//                         >
//                             <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
//                             BACK
//                         </Button>

//                     </div>





//                     {!(mawb3 && hawb3) && ImportsofMAWB.length > 0 && (
//                         <div className="table-responsive">
//                             <Table className="table table-striped table-hover">
//                                 <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
//                                     <tr className="text-center">
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">Sr</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">MAWB No</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">HAWB No</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">SIR No</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">Wt</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">Content Type</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">No of Packages</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">Remark</th>
//                                         <th style={{ backgroundColor: '#BADDDA' }} scope="col">Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>

//                                     {ImportsofMAWB.map((import2, index) =>

//                                         <tr className="text-center">
//                                             <td className="table-column">{index + 1}</td>
//                                             <td className="table-column">{import2.mawb}</td>
//                                             <td className="table-column">{import2.hawb.startsWith('000') ? '' : import2.hawb}</td>
//                                             <td className="table-column">{import2.sirNo}</td>
//                                             <td className="table-column">{import2.grossWeight}</td>
//                                             <td className="table-column">{import2.packageContentType}</td>
//                                             <td className="table-column">{import2.nop}</td>
//                                             <td className="table-column">{import2.importRemarks}</td>
//                                             <td className="table-column">
//                                                 <button
//                                                     type="button"
//                                                     className="btn me-md-2  btn-outline-primary"
//                                                     onClick={(e) => getByMAWBnoAndHAwb(import2.companyId, import2.branchId, import2.impTransId, import2.mawb, import2.hawb, import2.sirNo)}
//                                                 ><FontAwesomeIcon icon={faEdit} />

//                                                 </button>


//                                                 {dgdc_Status === 'Handed over to DGDC Cargo' && (
//                                                     <button
//                                                         type="button"
//                                                         className="btn gap-2  btn-outline-danger"
//                                                         onClick={(e) => DeleteImport(import2.companyId, import2.branchId, import2.impTransId, import2.mawb, import2.hawb, import2.sirNo)}
//                                                     > <FontAwesomeIcon icon={faTrash} />

//                                                     </button>

//                                                 )}

//                                             </td>
//                                         </tr>
//                                     )
//                                     }
//                                 </tbody>
//                             </Table>
//                         </div>
//                     )}
//                 </CardBody>
//             </Card >


//         </div>
//     );
// }

// export default Import_Model;





import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../Components/AuthProvider";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import Select from 'react-select';
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { Card, CardBody, Container, Row, Col, Form, FormGroup, Label, Input, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faClose, faPlaneCircleCheck, faPlaneUp, faRefresh, faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faSave, faTimes, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


function Import_Model() {
    const [errors, setErrors] = useState({});
    const [companyId, setcompanyId] = useState('');

    const [branchId1, setBranchId] = useState('');
    const [impTransId, setImpTransId] = useState('');
    const [impTransDate, setImpTransDate] = useState(); // Use appropriate initial value
    const [mawb, setMawb] = useState('');
    const [hawb, setHawb] = useState('');
    const [igmNo, setIgmNo] = useState('');
    const [igmDate, setIgmDate] = useState(''); // Use appropriate initial value
    const [sirNo, setSirNo] = useState('');
    const [sirDate, setSirDate] = useState(''); // Use appropriate initial value
    const [pctmNo, setPctmNo] = useState('');
    const [tpNo, setTpNo] = useState('');
    const [tpDate, setTpDate] = useState(null); // Use appropriate initial value
    const [airlineName, setAirlineName] = useState('');
    const [flightNo, setFlightNo] = useState('');
    const [flightDate, setFlightDate] = useState(); // Use appropriate initial value
    const [countryOrigin, setCountryOrigin] = useState('');
    const [portOrigin, setPortOrigin] = useState('');
    const [importerId, setImporterId] = useState('');
    const [iec, setIec] = useState('');
    const [sezEntityId, setSezEntityId] = useState('');
    const [consoleName, setconsoleName] = useState('');
    const [packageContentType, setPackageContentType] = useState('GOLD JEWELLERY (GLJ)');
    const [parcelType, setParcelType] = useState('');
    const [uomPackages, setUomPackages] = useState('');
    const [nop, setNop] = useState(1); // Use appropriate initial value
    const [importRemarks, setImportRemarks] = useState('');
    const [descriptionOfGoods, setDescriptionOfGoods] = useState('GLJ');
    const [chaCde, setChaCde] = useState('');
    const [assessableValue, setAssessableValue] = useState('');
    const [grossWeight, setGrossWeight] = useState(''); // Use appropriate initial value
    const [beRequestId, setBeRequestId] = useState('');
    const [beNo, setBeNo] = useState('');
    const [beDate, setBeDate] = useState(null); // Use appropriate initial value
    const [importAddress, setImportAddress] = useState('');
    const [status, setStatus] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [createdDate, setCreatedDate] = useState(''); // Use appropriate initial value
    const [editedBy, setEditedBy] = useState('');
    const [editedDate, setEditedDate] = useState(null); // Use appropriate initial value
    const [approvedBy, setApprovedBy] = useState('');
    const [approvedDate, setApprovedDate] = useState(null); // Use appropriate initial value
    const [FlightS, setFlightS] = useState([]);
    const [uomWeight, setuomWeight] = useState('KGS');
    const [parties, setParties] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const [partyName, setparty_Name] = useState('');
    const [consoles, setConsoles] = useState([]);
    const [pcktype, setPcktype] = useState([]);
    const [ImportsofMAWB, setImportsofMAWB] = useState([]);
    const [nsdl_Status, setNSDL_Status] = useState('');
    const [dgdc_Status, seTDGDC_Status] = useState('');
    const [CreatedUser, setCreatedUser] = useState('');
    const [approvedUser, setApprovedUser] = useState('');
    const [closeStatus, setcloseStatus] = useState('N');
    const [holdStatus, setHoldStatus] = useState('N');
    const [holdDate, setHoldDate] = useState('0000-00-00'); // You can initialize with the appropriate default date
    const [holdBy, setHoldBy] = useState('');
    const [hpStatus, setHpStatus] = useState('N');
    const [hpWeight, setHpWeight] = useState(0.000);
    const [pcStatus, setPcStatus] = useState('N');
    const [scStatus, setScStatus] = useState('N');
    const [cancelStatus, setCancelStatus] = useState('N');
    const [cancelRemarks, setCancelRemarks] = useState('');
    const [imposePenaltyAmount, setImposePenaltyAmount] = useState(0.000);
    const [imposePenaltyRemarks, setImposePenaltyRemarks] = useState('');
    const [handedOverRepresentativeId, sethandedOverRepresentativeId] = useState('');
    const [handedOverPartyId, sethandedOverPartyId] = useState('');
    const [handedOverToType, sethandedOverToType] = useState('');
    const [reasonforOverride, setReasonforOverride] = useState('');
    const [chaName, setchaName] = useState('');

    const [nsdlStatusDocs, setnsdlStatusDocs] = useState('');
    const [niptStatus, setniptStatus] = useState("N");
    const [importernameOnParcel, setimporternameOnParcel] = useState('');
    const [qrcodeUrl, setqrcodeUrl] = useState('');
    const [doNumber, setdoNumber] = useState('');
    const [doDate, setdoDate] = useState('');
    const [airlineCode, setAirlineCode] = useState('');
    const [outDate, setoutDate] = useState('');

    const [forwardedStatus, setForwardedStatus] = useState('N');
    const [noc, setNoc] = useState(0);
    const [dgdcSeepzInScan, setDgdcSeepzInScan] = useState(0);
    const [dgdcSeepzOutScan, setDgdcSeepzOutScan] = useState(0);
    const [dgdcCargoInScan, setDgdcCargoInScan] = useState(0);
    const [dgdcCargoOutScan, setDgdcCargoOutScan] = useState(0);



    const [niptCustomOfficerName, setniptCustomOfficerName] = useState('');
    const [niptCustomsOfficerDesignation, setniptCustomsOfficerDesignation] = useState('');
    const [niptDeputedFromDestination, setniptDeputedFromDestination] = useState('');
    const [niptDeputedToDestination, setniptDeputedToDestination] = useState('');
    const [niptDateOfEscort, setniptDateOfEscort] = useState('');
    const [niptApproverName, setniptApproverName] = useState('');
    const [niptApproverDesignation, setniptApproverDesignation] = useState('');
    const [niptApproverDate, setniptApproverDate] = useState('');
    const [wrongDepositFilePath, setwrongDepositFilePath] = useState('');
    const [wrongDepositwrongDepositRemarks, setwrongDepositwrongDepositRemarks] = useState('');
    const [wrongDepositStatus, setwrongDepositStatus] = useState('');
    const [detentionReceiptNo, setdetentionReceiptNo] = useState('');
    const [mopStatus, setMopStatus] = useState('N');


    const [cartingAgent, setcartingAgent] = useState('');
    const [partyRepresentativeId, setpartyRepresentativeId] = useState('');




    const {
        userId,
        username,
        branchId,
        companyid,
        logintype,
        logintypeid,
        userType

    } = useContext(AuthContext);

    const navigate = useNavigate();

    const location = useLocation();
    const transId2 = location.state?.transId2;
    const mawb2 = location.state?.mawb2;
    const hawb2 = location.state?.hawb2;
    const sir2 = location.state?.sir2;
    const transId3 = location.state?.transId3;
    const mawb3 = location.state?.mawb3;
    const hawb3 = location.state?.hawb3;
    const sir3 = location.state?.sir3;
    const searchCriteria = location.state?.searchCriteria;


    const currentPage = location.state?.currentPage;

    const Handleback = () => {
        navigate(`/parent/import`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } })
    };

    useEffect(() => {
        if (mawb2 && hawb2) {
            getByMAWBnoAndHAwb(companyid, branchId, transId2, mawb2, hawb2, sir2);
            getImportsOfMAWB(companyid, branchId, mawb2);
        };
        if (mawb3 && hawb3) {
            getByMAWBnoAndHAwb(companyid, branchId, transId3, mawb3, hawb3, sir3);
        };
    }, [])

    const getImportsOfMAWB = (compId, branchId, MAWBNo) => {
        Rate_Chart_Service.getByMAWBNo(compId, branchId, MAWBNo).then((res) => {
            setImportsofMAWB(res.data);
        })
    }




    const handleFlightNumberChange = (event) => {
        const enteredFlightNumber = event.target.value;

        setErrors(prevErrors => ({ ...prevErrors, flightNo: '' }));

        // Check if the length is greater than or equal to 2
        if (enteredFlightNumber.length >= 2) {
            // Extract the first two letters
            const firstTwoLetters = enteredFlightNumber.substring(0, 2).toUpperCase();
            //   console.log("firstTwoLetters "+ firstTwoLetters);
            // Filter airlines based on AirlineShortName
            const matchingAirline = FlightS.find(airline => airline.airlineShortName === firstTwoLetters);
            //  console.log("Matched "+ matchingAirline);
            // Set airline code and name if a match is found
            if (matchingAirline) {
                setAirlineCode(matchingAirline.airlineCode);
                setAirlineName(matchingAirline.airlineName);
            } else {
                // Handle the case where no matching airline is found
                // You can set default values or handle it according to your requirements
                setAirlineCode('');
                setAirlineName('');
            }
        }


        if (!enteredFlightNumber) {
            setAirlineCode('');
            setAirlineName('');
        }
        // Update the flight number in the state
        setFlightNo(enteredFlightNumber);
        // console.log("flight "+ enteredFlightNumber);
        // console.log("airline code  "+ airlineCode);
        // console.log("airline Name "+ airlineName);

    };


    // new method

    // const getAirlineNameByShortName = async (shortname) => {
    //     try {
    //         const response = await Rate_Chart_Service.getAirlineNameByShortName(companyid, branchId, shortname);
    //         setAirlineName(response.data.airlineName);
    //         setAirlineCode(response.data.airlineCode);
    //     } catch (error) {
    //         // Handle the error here
    //         console.error('An error occurred:', error);
    //         // You can also set an error state or show an error message to the user.
    //     }
    // };
    // useEffect(() => {
    //     if (flightNo && flightNo.length === 2) {
    //         getAirlineNameByShortName(flightNo);
    //     }
    //     if (!flightNo) {
    //         setAirlineName('');
    //         setAirlineCode('');
    //     }
    // }, [flightNo]);



    // const handleValidation = () => {
    //     let formIsValid = true;
    //     const newErrors = {};

    //     // Validate serviceShortDescription
    //     if(pcStatus !=='Y')
    //     {


    //     if (mawb.length !== 11) {
    //         formIsValid = false;
    //         newErrors.mawb = 'mawb should be an 11-digit';
    //     }
    // }

    //     if (!mawb) {
    //         formIsValid = false;
    //         newErrors['mawb'] = 'mawb is required.';

    //     }

    //     // Validate serviceType
    //     if (!flightNo) {
    //         formIsValid = false;
    //         newErrors['flightNo'] = 'FlightNo is required.';

    //     }

    //     if (!igmNo) {
    //         formIsValid = false;
    //         newErrors['igmNo'] = 'IgmNo is required.';

    //     }
    //     if (!consoleName) {
    //         formIsValid = false;
    //         newErrors['consoleName'] = 'Console is required.';

    //     }
    //     if (!grossWeight || grossWeight <= 0) {
    //         formIsValid = false;
    //         newErrors['grossWeight'] = 'Gross weight must be greater than 0.';
    //     }

    //     if (!importerId) {
    //         formIsValid = false;
    //         newErrors['importerId'] = 'importerId is required.';

    //     }
    //     if (!partyName) {
    //         formIsValid = false;
    //         newErrors['partyName'] = 'Party is required.';

    //     }
    //     // if (!hawb) {
    //     //     formIsValid = false;
    //     //     newErrors['hawb'] = 'Hawb is required.';

    //     // }
    //     if (!packageContentType) {
    //         formIsValid = false;
    //         newErrors['packageContentType'] = 'PackageContentType is required.';

    //     }

    //     // console.warn(newErrors);
    //     setErrors(newErrors);
    //     return formIsValid;
    // };



    const handleValidation = () => {
        let formIsValid = true;
        const newErrors = {};

        // Validate serviceShortDescription
        if (!(pcStatus === 'Y' || niptStatus === 'Y')) {


            if (mawb.length !== 11) {
                formIsValid = false;
                newErrors.mawb = 'mawb should be an 11-digit';
            }
        }

        if (!mawb) {
            formIsValid = false;
            newErrors['mawb'] = 'mawb is required.';
        }

        // Validate serviceType
        if (!(pcStatus === 'Y' || niptStatus === 'Y')) {

            if (!flightNo) {
                formIsValid = false;
                newErrors['flightNo'] = 'FlightNo is required.';
            }
        }

        if (pcStatus !== 'Y') {
            if (!igmNo) {
                formIsValid = false;
                newErrors['igmNo'] = 'igmNo is required.';
            }

            if (!igmDate) {
                formIsValid = false;
                newErrors['igmDate'] = 'igmDate is required.';
            }
        }







        if (!consoleName) {
            formIsValid = false;
            newErrors['consoleName'] = 'Console is required.';

        }

        if (!nop || nop <= 0) {
            formIsValid = false;
            newErrors['nop'] = 'please enter nop';
        }


        if ((pcStatus !== 'Y') && (!grossWeight || grossWeight <= 0)) {
            formIsValid = false;
            newErrors['grossWeight'] = 'Gross weight must be greater than 0.';
        }



        // if (!grossWeight || grossWeight <= 0) {
        //     formIsValid = false;
        //     newErrors['grossWeight'] = 'Gross weight must be greater than 0.';
        // }

        if (!importerId) {
            formIsValid = false;
            newErrors['importerId'] = 'importerId is required.';

        }
        if (!partyName) {
            formIsValid = false;
            newErrors['partyName'] = 'Party is required.';

        }
        if (!(pcStatus === 'Y' || niptStatus === 'Y')) {

            if (!packageContentType) {
                formIsValid = false;
                newErrors['packageContentType'] = 'PackageContentType is required.';

            }
        }


        if (niptStatus === 'Y') {

            if (!assessableValue) {
                formIsValid = false;
                newErrors['assessableValue'] = 'assessableValue is required.';
            }

            if (!beNo) {
                formIsValid = false;
                newErrors['beNo'] = 'beNo is required.';
            }

            if (!beRequestId) {
                formIsValid = false;
                newErrors['beRequestId'] = 'beRequestId is required.';
            }

            if (!beDate) {
                formIsValid = false;
                newErrors['beDate'] = 'beDate is required.';
            }
        }


        // console.warn(newErrors);
        setErrors(newErrors);
        return formIsValid;
    };

    const [ConsoleNameById, setConsoleNameById] = useState();
    const getConsoleNameById = async (id) => {

        Rate_Chart_Service.getUsernameByexternalUserId(companyid, branchId, id)
            .then((res) => {
                setConsoleNameById(res.data);


            });

    };




    const [chas, setChas] = useState([]);
    const [chanameByCode, setchanameByCode] = useState(null);
    // const getChaname = async (code) => {
    //     const name = await Rate_Chart_Service.getUsernameByexternalUserId(companyid, branchId, code);

    //     console.log(name);
    //     console.log(name.data);
    //     setchanameByCode(name.data);
    // }


    const findCHAs = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'CHA')
        setChas(partyOptions);

    };


    const handleCHAChange = selectedOption => {
        setchanameByCode(selectedOption);
        setchaName(selectedOption ? selectedOption.value : '');
    };





    const getByMAWBnoAndHAwb = async (compId, branchID, transId, mawb, hawb, sirNo) => {

        Rate_Chart_Service.GetByMAWBandHAWB(compId, branchID, transId, mawb, hawb, sirNo).then((res) => {
            setMawb(res.data.mawb);
            setHawb(res.data.hawb);
            setIgmNo(res.data.igmNo);
            setSirNo(res.data.sirNo);
            setSirDate(res.data.sirDate);
            setAirlineName(res.data.airlineName);
            setFlightNo(res.data.flightNo);

            setIgmDate(new Date(res.data.igmDate));
            setBeDate(new Date(res.data.beDate));
            setFlightDate(new Date(res.data.flightDate));

            setCountryOrigin(res.data.countryOrigin);
            setPortOrigin(res.data.portOrigin);
            setImporterId(res.data.importerId);
            setcloseStatus(res.data.closeStatus);

            getPartyByID(companyid, branchId, res.data.importerId, res.data.chaName);

            setIec(res.data.iec);
            setSezEntityId(res.data.sezEntityId);
            setconsoleName(res.data.consoleName);

            if (res.data.consoleName !== null && res.data.consoleName !== '') {
                getConsoleNameById(res.data.consoleName);
            }



            setPackageContentType(res.data.packageContentType);
            setNop(res.data.nop);
            setParcelType(res.data.parcelType);
            setImportRemarks(res.data.importRemarks);
            setDescriptionOfGoods(res.data.descriptionOfGoods);
            setChaCde(res.data.chaCde);
            setAssessableValue(res.data.assessableValue);
            setGrossWeight(res.data.grossWeight);
            setBeRequestId(res.data.beRequestId);
            setBeNo(res.data.beNo);
            // sethppackageno(res.data.hppackageno);
            setStatus(res.data.status);
            setTpNo(res.data.tpNo);
            setPctmNo(res.data.pctmNo);

            setHoldStatus(res.data.holdStatus);
            setHoldDate(res.data.holdDate);
            setHoldBy(res.data.holdBy);
            setHpStatus(res.data.hpStatus);
            // setHpWeight(res.data.hpWeight);
            setPcStatus(res.data.pcStatus);
            setScStatus(res.data.scStatus);
            setCancelStatus(res.data.cancelStatus);
            setCancelRemarks(res.data.cancelRemarks);
            setImposePenaltyAmount(res.data.imposePenaltyAmount);
            setImposePenaltyRemarks(res.data.imposePenaltyRemarks);


            setnsdlStatusDocs(res.data.nsdlStatusDocs);
            setReasonforOverride(res.data.reasonforOverride);

            // getApprovedUser(res.data.approvedBy, companyid, branchID);


            setApprovedBy(res.data.approvedBy);



            // getCreatedUser(res.data.createdBy, companyid, branchID);




            setTpDate(res.data.tpDate);
            setEditedBy(res.data.editedBy);
            setEditedDate(res.data.editedDate);
            setCreatedDate(res.data.createdDate);
            setApprovedDate(res.data.approvedDate);
            setCreatedBy(res.data.createdBy);
            setuomWeight(res.data.uomWeight);
            setImportAddress(res.data.importAddress);
            setUomPackages(res.data.uomPackages);
            setcompanyId(res.data.companyId);
            setBranchId(res.data.DatebranchId);
            setImpTransId(res.data.impTransId);
            setImpTransDate(res.data.impTransDate);
            setNSDL_Status(res.data.nsdl_Status);
            seTDGDC_Status(res.data.dgdc_Status);
            setoutDate(res.data.outDate);


            sethandedOverRepresentativeId(res.data.handedOverRepresentativeId);
            sethandedOverPartyId(res.data.handedOverPartyId);
            sethandedOverToType(res.data.handedOverToType);
            setniptStatus(res.data.niptStatus);
            setimporternameOnParcel(res.data.importernameOnParcel);
            setqrcodeUrl(res.data.qrcodeUrl);
            setdoNumber(res.data.doNumber);
            setdoDate(res.data.doDate);
            setchaName(res.data.chaName);

            // if (res.data.chaName !== null && res.data.chaName !== '') {
            //     getChaname(res.data.chaName);
            // }

            setAirlineCode(res.data.airlineCode)

            setniptApproverDate(res.data.niptApproverDate);
            setniptApproverDesignation(res.data.niptApproverDesignation);
            setniptApproverName(res.data.niptApproverName);
            setniptCustomOfficerName(res.data.niptCustomOfficerName);
            setniptCustomsOfficerDesignation(res.data.niptCustomsOfficerDesignation);
            setniptDateOfEscort(res.data.niptDateOfEscort);
            setniptDeputedToDestination(res.data.niptDeputedToDestination);
            setniptDeputedFromDestination(res.data.niptDeputedFromDestination);
            setwrongDepositFilePath(res.data.wrongDepositFilePath);
            setwrongDepositwrongDepositRemarks(res.data.wrongDepositwrongDepositRemarks);
            setwrongDepositStatus(res.data.wrongDepositStatus);
            setdetentionReceiptNo(res.data.detentionReceiptNo);
            setForwardedStatus(res.data.forwardedStatus);
            setNoc(res.data.noc);
            setDgdcSeepzInScan(res.data.dgdcSeepzInScan);
            setDgdcSeepzOutScan(res.data.dgdcSeepzOutScan);
            setDgdcCargoInScan(res.data.dgdcCargoInScan)
            setDgdcCargoOutScan(res.data.dgdcCargoOutScan);
            setMopStatus(res.data.mopStatus);

            setcartingAgent(res.data.cartingAgent);
            setpartyRepresentativeId(res.data.partyRepresentativeId);

        })
    };







    const makefieldEmpty = () => {

        setHawb('');
        setSirNo('');
        setPackageContentType('LAB GROWN DIAMOND(LGD)');
        setNop(1);
        setParcelType('');
        setImportRemarks('');
        setDescriptionOfGoods('LGD');
        setChaCde('');
        setAssessableValue('');
        setBeRequestId('');
        setBeNo('');
        setBeDate('');
        setTpDate('');
        setStatus('');
        setApprovedBy('');
        setCreatedBy('');
        setuomWeight('');
        setImportAddress('');
        setUomPackages('');
        setTpNo('');
        setPctmNo('');
        setErrors('');
        setAssessableValue('');
        setGrossWeight('');
        setBeRequestId('');
        setImpTransId('');
        setImpTransDate('');
        setApprovedBy('');
        setEditedBy('');
        setEditedDate('');
        setCreatedDate('');
        setApprovedDate('');
        setCreatedBy('');
        setuomWeight('KGS');
        setoutDate('');
        setImportAddress('');
        setNSDL_Status('');
        seTDGDC_Status('');
        setcompanyId('');
        setCreatedUser('');
        setApprovedUser('');
        sethandedOverPartyId('');
        sethandedOverRepresentativeId('');
        sethandedOverToType('');
        setniptStatus('');
        setqrcodeUrl('');
        setimporternameOnParcel('');
        setdoNumber('');
        setdoDate('');
        setImporterId('');
        setparty_Name('');
        setSezEntityId('');
        setIec('');
        setchaName('');
        setniptApproverDate('');
        setniptApproverDesignation('');
        setniptApproverName('');
        setniptCustomOfficerName('');
        setniptCustomsOfficerDesignation('');
        setniptDateOfEscort('');
        setniptDeputedToDestination('');
        setniptDeputedFromDestination('');
        setwrongDepositFilePath('');
        setwrongDepositwrongDepositRemarks('');
        setwrongDepositStatus('');
        setdetentionReceiptNo('');
        setForwardedStatus("N");
        setNoc(0);
        setDgdcSeepzInScan(0);
        setDgdcSeepzOutScan(0);
        setDgdcCargoInScan(0)
        setDgdcCargoOutScan(0);
        setMopStatus('N');

        setcartingAgent('');
        setpartyRepresentativeId('');
    };


    useEffect(() => {

        const foundParty = chas.find(chas => chas.value === chaName);
        if (foundParty) {
            setchanameByCode(foundParty);
        } else {
            setchanameByCode(null);
        }

    }, [chas, chaName]);


    useEffect(() => {
        // findAirlines();
        findParties();
        findPCKTYPE();
        findConsoles();
        findCHAs();
        findAirlines();
    }, []);

    const findExternalPartyByType = async (compid, branchid, Type) => {

        const partyResponse = await Rate_Chart_Service.getExternalUserByTypeForImport(compid, branchid, Type);
        const partyOptions = partyResponse.data.map(externalUser => ({
            value: externalUser.externaluserId,
            label: externalUser.userName
        }));
        return partyOptions;

    };
    const findConsoles = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'console')
        setConsoles(partyOptions);

    };

    const findPCKTYPE = async () => {
        const PCKTYPEResponse = await Rate_Chart_Service.getjarsByJarId('J00008', companyid, branchId);
        const partyOptions = PCKTYPEResponse.data.map(jar => ({
            value: jar.jarId,
            label: jar.jarDtlDesc
        }));
        setPcktype(partyOptions);
    };


    const handleConsoleChange = selectedOption => {
        setErrors(prevErrors => ({ ...prevErrors, consoleName: '' }));
        setconsoleName(selectedOption ? selectedOption.value : '');
        setConsoleNameById(selectedOption ? selectedOption.label : '')

    };

    const handlepckgtype = selectedOption => {

        setErrors(prevErrors => ({ ...prevErrors, packageContentType: '' }));

        setPackageContentType(selectedOption ? selectedOption.label : '');
        if (selectedOption) {
            setPackageContentType(selectedOption ? selectedOption.label : '');

            // Extract text within parentheses from packageContentType
            const extractedValue = extractValueWithinParentheses(selectedOption ? selectedOption.label : '');
            // console.log("extractedValue "+extractedValue);
            setDescriptionOfGoods(extractedValue);
        } else {
            setPackageContentType('');
            setDescriptionOfGoods(''); // Reset descriptionOfGoods if no package content type is selected
        }


    };
    // Function to extract text within parentheses from package content type
    const extractValueWithinParentheses = (text) => {
        const match = text.match(/\(([^)]+)\)/); // Using regex to find text within parentheses
        return match ? match[1] : ''; // Extracted text or empty string if not found
    };



    const findParties = async () => {
        const partyResponse = await Rate_Chart_Service.getAllParties(companyid, branchId);
        const partyOptions = partyResponse.data.map(party => ({
            value: party.partyId,
            label: party.partyName
        }));
        setParties(partyOptions);

    };

    const findAirlines = async () => {
        const AirResponse = await Rate_Chart_Service.getAllairline(companyid, branchId);
        // const AirOptions = AirResponse.data.map(party => ({
        //     value: party.airlineCode,
        //     label: party.flightNo
        // }));
        setFlightS(AirResponse.data);
    };



    const handlePartyChange = async (selectedOption, { action }) => {


        setErrors(prevErrors => ({ ...prevErrors, partyName: '' }));

        if (action === 'clear') {
            setparty_Name('');
            setIec('');
            setSezEntityId('');
            setImporterId('');
            setimporternameOnParcel('');
        }
        else {
            setSelectedParty(selectedOption);
            setimporternameOnParcel(selectedOption ? selectedOption.label : '');
            // setparty_Name(selectedOption ? selectedOption.label : '');
            setImporterId(selectedOption ? selectedOption.value : '');
            getPartyByID(companyid, branchId, selectedOption ? selectedOption.value : '')
        }
    };

    const customFilterOption = (candidate, input) => {
        const inputLower = input.toLowerCase();
        const labelLower = candidate.label.toLowerCase();
        return candidate.data.__isNew__ || labelLower.startsWith(inputLower);
    };

    const handleFlightChange = (selectedOption, { action }) => {

        if (action === 'clear') {
            setFlightNo('');
            setAirlineCode('')
            setAirlineName('');
        }
        else {
            setFlightNo(selectedOption ? selectedOption.label : '');
            setAirlineCode(selectedOption ? selectedOption.value : '')
            getSingleFlightlist(selectedOption ? selectedOption.value : '');
        }
    };


    const getSingleFlightlist = async (airlineCode) => {

        Rate_Chart_Service.getAirlineNameByCode(companyid, branchId, airlineCode)
            .then((response) => {

                setAirlineName(response.data);

            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };





    const addImport = async () => {
        setIsSubmitting(true);

        try {
            const res = await Rate_Chart_Service.addImport(companyid, branchId, userId, importData);

            // Continue with the import processing
            getImportsOfMAWB(res.data.companyId, res.data.branchId, res.data.mawb);
            getByMAWBnoAndHAwb(res.data.companyId, res.data.branchId, res.data.impTransId, res.data.mawb, res.data.hawb, res.data.sirNo);

            const toastContent = `Import with SIR No  <strong> ${res.data.sirNo} </strong> Added Successfully!`;
            const contentWidth = toastContent.length * 10;
            toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                style: { width: `${contentWidth}px` },
            });
        } catch (error) {
            // Handle the error response, e.g., show an error toast
            const errorMessage = error.response ? error.response.data : "An error occurred during import.";
            const contentWidth = errorMessage.length * 12;
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: `${contentWidth}px` },
            });
        } finally {
            // This block will be executed regardless of success or failure
            setIsSubmitting(false);
        }
    };


    // const updateModify = () => {

    //     Rate_Chart_Service.ModifyupdateImport(companyid, branchId, userId, importData).then((res) => {
    //         console.log(res.data);
    //         getImportsOfMAWB(res.data.companyId, res.data.branchId, res.data.mawb);
    //         getByMAWBnoAndHAwb(res.data.companyId, res.data.branchId, res.data.impTransId, res.data.mawb, res.data.hawb, res.data.sirNo);
    //         toast.success('Import Updated Successfully !', {
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 600,
    //         });
    //     })
    // };

    const updateModify = () => {

        Rate_Chart_Service.ModifyupdateImport(companyid, branchId, userId, importData).then((res) => {

            // console.log("*******Data For Modify************ ");

            if (res.status === 200) {
                console.log(res.data);
                getImportsOfMAWB(res.data.companyId, res.data.branchId, res.data.mawb);
                getByMAWBnoAndHAwb(res.data.companyId, res.data.branchId, res.data.impTransId, res.data.mawb, res.data.hawb, res.data.sirNo);
                toast.success('Import Updated Successfully !', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 600,
                });
            } else {

            }
        }).catch((error) => {


            const errorMessage = error.response ? error.response.data : "An error occurred during import.";
            const contentWidth = errorMessage.length * 12;
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: { width: `${contentWidth}px` },
            });

        });
    };



    const getPartyByID = async (companyId, branchId, id3, chaNameReceived) => {
        try {
            const res = await Rate_Chart_Service.getPartyByIdNew(companyId, branchId, id3, 'IMP');
            if (res.data) {
                setparty_Name(res.data.partyName);
                // setchaName(res.data.status);
                setIec(res.data.iecNo);
                setSezEntityId(res.data.entityId);

                setchaName(chaNameReceived ? chaNameReceived : res.data.status);

            } else {
                console.log("No data received from getPartyById API");
            }
        } catch (error) {
            console.error("Error fetching party data:", error);
        }
    };
    const getCreatedUser = (id3, companyid, branchid) => {
        Rate_Chart_Service.getUserbyUserId(id3, companyid, branchid).then((res) => {
            setCreatedUser(res.data.user_Name);
            // alert(CreatedUser);
        })
    };

    const getApprovedUser = (id2, companyid, branchid) => {
        if (id2) {
            Rate_Chart_Service.getUserbyUserId(id2, companyid, branchid).then((res) => {
                setApprovedUser(res.data.user_Name);
            })
        };
    };





    const DeleteImport = (compaId, branchId, transId, mawbno, hawbno, sir) => {
        {


            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    Rate_Chart_Service.deleteimportofmawb(compaId, branchId, transId, mawbno, hawbno, sir).then((res) => {
                        // Swal.fire('Service Deleted Successfully', 'You clicked the button', 'success');

                        getImportsOfMAWB(compaId, branchId, mawbno);

                        // console.log(res.data)
                        makefieldEmpty();

                    }
                    )

                    toast.error('Service Deleted Successfully !', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 700,
                    });
                }
            })




        }
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const updateImportAll = () => {


        let totalNops = 0;
        let totalWeight = 0;

        // Iterate over the importData to calculate totals
        ImportsofMAWB.forEach((importItem) => {
            totalNops += importItem.nop;
            totalWeight += importItem.grossWeight;
        });


        Swal.fire({
            title: 'Are you sure?',
            html: `<b>Total Packages:  ${totalNops} </b> ,<b>Total Master Weight: ${totalWeight.toFixed(3)} KGS</b> </br> You won't be able to add more imports!`,
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                icon: 'icon-smaller' // Apply the custom class to the icon
            },
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, close it!'
        }).then((result) => {
            if (result.isConfirmed) {

                Rate_Chart_Service.updateImport(companyid, branchId, userId, importData).then((resList) => {
                    // Iterate over the list of Import objects returned by the updateImport method
                    resList.data.forEach((importItem) => {
                        // Perform actions for each Import item

                        // Call the getImportsOfMAWB method
                        getImportsOfMAWB(importItem.companyId, importItem.branchId, importItem.mawb);

                        // Call the getByMAWBnoAndHAwb method
                        getByMAWBnoAndHAwb(importItem.companyId, importItem.branchId, importItem.impTransId, importItem.mawb, importItem.hawb, importItem.sirNo);


                    });
                    toast.error('Master Closed Successfully !', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 700,
                    });
                });

            }
        })




    };



    const SaveorSubmit = () => {
        // alert("On the Way")
        const isFormValid = handleValidation();
        if (isFormValid) {
            if (!(pcStatus === 'Y' || niptStatus === 'Y')) {
                // Continue with the condition related to airlineCode only if pcStatus and niptStatus are not 'Y'
                if (airlineCode) {
                    // alert("in")
                    if (status) {
                        if (mawb2 && hawb2) {
                            updateModify();
                        }
                        if (!mawb2 && !hawb2) {
                            updateModify();
                        }
                    } else {
                        addImport();
                    }
                } else {
                    const errorMessage = "Your Entered Flight Number's Airline is not Present In Airline Master! Please add Airline in Airline Master!!!";
                    const contentWidth = errorMessage.length * 5;

                    toast.error(errorMessage, {
                        position: toast.POSITION.CENTER,
                        autoClose: 6000,
                        style: { width: `${contentWidth}px` },
                    });
                }
            } else {
                if (status) {
                    if (mawb2 && hawb2) {
                        updateModify();
                    }
                    if (!mawb2 && !hawb2) {
                        updateModify();
                    }
                }

                else {

                    addImport();
                }

            }
        } else {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
    };






    const importData = {
        companyId, branchId,
        uomWeight, impTransId, impTransDate, mawb, hawb, igmNo, igmDate, sirNo, sirDate, pctmNo, tpNo, tpDate, airlineName,
        flightNo, flightDate, countryOrigin, portOrigin, importerId, iec, sezEntityId, consoleName, packageContentType, parcelType, niptStatus, importernameOnParcel, qrcodeUrl,
        uomPackages, nop, importRemarks, descriptionOfGoods, chaCde, assessableValue, grossWeight, beRequestId, beNo, beDate, handedOverToType, handedOverPartyId, handedOverRepresentativeId,
        importAddress, status, createdBy, createdDate, editedBy, editedDate, approvedBy, approvedDate, dgdc_Status, nsdl_Status, closeStatus, reasonforOverride, nsdlStatusDocs,
        holdStatus, holdDate, holdBy, hpStatus, pcStatus, scStatus, cancelStatus, cancelRemarks, imposePenaltyAmount, imposePenaltyRemarks, doNumber, doDate, chaName, airlineCode, outDate,
        niptCustomOfficerName, niptCustomsOfficerDesignation, niptDeputedFromDestination, mopStatus,
        niptDeputedToDestination, niptDateOfEscort, niptApproverName, niptApproverDesignation, niptApproverDate, wrongDepositFilePath, wrongDepositwrongDepositRemarks, wrongDepositStatus, detentionReceiptNo,
        forwardedStatus, noc, dgdcSeepzInScan, dgdcSeepzOutScan, dgdcCargoInScan, dgdcCargoOutScan, cartingAgent, partyRepresentativeId
    };


    function handleKeyPress(event) {
        if (event.key === 'Enter') {

            handleDateChange(new Date()); // Call the function to set the current date
        }
    }




    const handleDateChange = (date1) => {
        setErrors(prevErrors => ({ ...prevErrors, igmDate: '' }));
        setIgmDate(date1);
        setFlightDate(date1);
    };

    const handleDateChangeFlight = (date2) => {
        setFlightDate(date2);
    };
    const handleDateChangeBE = (date) => {
        setErrors(prevErrors => ({ ...prevErrors, beDate: '' }));
        setBeDate(date);
    };

    function handleInputChange(e) {
        const inputValue = e.target.value;
        const numericInput = inputValue.replace(/[^0-9.]/g, '');
        const parts = numericInput.split('.');
        const integerPart = parts[0];
        let decimalPart = parts[1];

        // Limit decimal places if needed
        if (decimalPart !== undefined) {
            decimalPart = `.${decimalPart.slice(0, 3)}`;
        }

        const sanitizedInput = decimalPart !== undefined ? `${integerPart}${decimalPart}` : integerPart;
        return sanitizedInput;
    };

    function handleNopChange(e) {
        setErrors(prevErrors => ({ ...prevErrors, nop: '' }));
        const inputValue = e.target.value;
        const numericInput = inputValue.replace(/[^0-9]/g, '');
        setNop(numericInput);
    };



    const handleCheckboxChange = (e) => {
        console.log('handleCheckboxChange : ', e.target.checked);
        setErrors([]);
        // Update niptStatus based on whether the checkbox is checked
        setniptStatus(e.target.checked ? 'Y' : 'N');
    };








    console.log('Import \n ', importData);




    return (
        <div className="Container" >
            <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                icon={faPlaneUp}
                style={{
                    marginRight: '8px',
                    color: 'black', // Set the color to golden
                }}
            />Add New Import</h5>


            <Card style={{ backgroundColor: "#F8F8F8" }}>
                <CardBody>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">MAWB No.</Label> <span className="required">*</span>
                                <Input
                                    type="text"
                                    name="mawb"
                                    className="form-control inputField"
                                    id={(mawb3 && hawb3) ? 'service' : 'mawb'}
                                    readOnly={(mawb3 && hawb3)}
                                    value={mawb}
                                    maxLength={19} // Add this line to set the maximum length
                                    style={{ height: '40px', borderColor: errors.mawb ? '#f52b2b' : '' }}
                                    // onChange={(e) => setMawb(e.target.value)}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setErrors(prevErrors => ({ ...prevErrors, mawb: '' }));
                                        setMawb(value);
                                    }}
                                />
                                {errors.mawb && (
                                    <div className="error-message">
                                        {errors.mawb}
                                    </div>
                                )}
                            </FormGroup>


                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">IGM No.</Label>  {pcStatus === "N" ? <span className="required">*</span> : ''}
                                <Input
                                    type="text" name="igmNo"
                                    className={` form-control ${errors.igmNo ? 'error-border' : ''}`}
                                    value={igmNo}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                    maxLength={20}
                                    style={{ borderColor: errors.igmNo ? '#f52b2b' : '' }}
                                    // onChange={(e) => setIgmNo(e.target.value)}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setErrors(prevErrors => ({ ...prevErrors, igmNo: '' }));
                                        setIgmNo(value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ' || e.keyCode === 32) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                {errors.igmNo && (
                                    <div className="error-message">
                                        {errors.igmNo}
                                    </div>
                                )}
                            </FormGroup>

                        </Col>



                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">IGM Date</Label> {pcStatus === "N" ? <span className="required">*</span> : ''}
                                <div> {/* Wrap in an input group */}
                                    <DatePicker
                                        selected={igmDate}
                                        onKeyDown={handleKeyPress}
                                        onChange={handleDateChange}
                                        wrapperClassName="custom-react-datepicker-wrapper"
                                        dateFormat="dd/MM/yyyy"
                                        id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                        readOnly={mawb3 && hawb3}
                                        value={igmDate}
                                        className={`form-control border-right-0 inputField ${errors.igmDate ? 'error-border' : ''}`}
                                        customInput={<input style={{ width: '100%' }} />}
                                    />

                                </div>
                                {errors.igmDate && (
                                    <div className="error-message">
                                        {errors.igmDate}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Airline Name</Label> {niptStatus === "N" ? <span className="required">*</span> : ''}
                                <Input
                                    type="text" name="airlineName"
                                    className="form-control inputField"
                                    value={airlineName}
                                    id='service' readOnly
                                    onChange={(e) => setAirlineName(e.target.value)}
                                    tabIndex="-1"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* 2nd */}

                    <Row>


                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Flight No.</Label> {niptStatus === "N" ? <span className="required">*</span> : ''}
                                <Input
                                    type="text" name="flightNo"
                                    className={errors.flightNo ? 'error-border' : ''}
                                    value={flightNo}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={(mawb3 && hawb3)}
                                    onChange={handleFlightNumberChange}
                                />

                                {errors.flightNo && (
                                    <div className="error-message">
                                        {errors.flightNo}
                                    </div>
                                )}
                            </FormGroup>

                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Flight Date</Label>
                                <div> {/* Wrap in an input group */}
                                    <DatePicker
                                        selected={flightDate}
                                        onChange={handleDateChangeFlight}
                                        wrapperClassName="custom-react-datepicker-wrapper"
                                        id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                        readOnly={mawb3 && hawb3}
                                        // minDate={today}
                                        dateFormat="dd/MM/yyyy"
                                        value={flightDate} // Set the value from the database
                                        className="form-control InputField"
                                        customInput={<input style={{ width: '100%' }} />}
                                    />

                                </div>
                            </FormGroup>
                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Country of Origin</Label>
                                <Input
                                    type="text" name="countryOrigin"
                                    className="form-control"
                                    value={countryOrigin}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    maxLength={30}
                                    readOnly={mawb3 && hawb3}
                                    onChange={(e) => setCountryOrigin(e.target.value)}
                                    onBlur={(e) => {
                                        const country = e.target.value;
                                        if (!portOrigin) {
                                            setPortOrigin(country);
                                        }
                                    }}

                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Port of Origin</Label>
                                <Input
                                    type="text" name="portOrigin"
                                    className="form-control"
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                    maxLength={30}
                                    value={portOrigin}
                                    onChange={(e) => setPortOrigin(e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                    </Row>



                    {/* 3rd */}

                    <Row>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Select Console</Label> <span className="required">*</span>

                                <Select
                                    options={consoles}
                                    value={{ value: ConsoleNameById, label: ConsoleNameById }}
                                    onChange={handleConsoleChange}
                                    isClearable
                                    className={errors.consoleName ? 'error-border' : ''}
                                    isDisabled={mawb3 && hawb3}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #ccc'
                                            }
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none'
                                        }),
                                        dropdownIndicator: () => ({
                                            display: 'none'
                                        })
                                    }}
                                />
                                {errors.consoleName && (
                                    <div className="error-message">
                                        {errors.consoleName}
                                    </div>
                                )}

                            </FormGroup>
                        </Col>



                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Select Importer</Label> <span className="required">*</span>
                                <Select
                                    options={parties}
                                    placeholder="Select a party"
                                    isClearable
                                    value={{ value: partyName, label: partyName }}
                                    onChange={handlePartyChange}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                    filterOption={customFilterOption}
                                    className={errors.partyName ? 'error-border' : ''}
                                    isDisabled={mawb3 && hawb3}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #ccc'
                                            }
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none'
                                        }),
                                        dropdownIndicator: () => ({
                                            display: 'none'
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            display: 'flex',
                                            color: 'gray',
                                        }),
                                    }}
                                />
                                {errors.partyName && (
                                    <div className="error-message">
                                        {errors.partyName}
                                    </div>
                                )}

                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">IEC No.</Label>
                                <Input
                                    type="text" name="iec"
                                    className="form-control"
                                    value={iec}
                                    onChange={(e) => setIec(e.target.value)}
                                    id="service"
                                    readOnly
                                    tabIndex="-1"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">SEZ Entity Id</Label>

                                <Input type="text" name="sezEntityId"
                                    className="form-control"
                                    value={sezEntityId}
                                    onChange={(e) => setSezEntityId(e.target.value)}
                                    id="service"
                                    readOnly
                                    tabIndex="-1"
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    {/* 4th */}


                    <Row>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Import Remarks</Label>
                                <Input
                                    type="text" name="importRemarks"
                                    className="form-control"
                                    value={importRemarks}
                                    maxLength={150}
                                    onChange={(e) => setImportRemarks(e.target.value)}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />
                            </FormGroup>
                        </Col>


                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Importer Name On Parcel</Label>

                                <Input type="text" name="sezEntityId"
                                    className="form-control"
                                    value={importernameOnParcel}
                                    onChange={(e) => setimporternameOnParcel(e.target.value)}
                                    id={(importerId !== 'NONE' || (mawb3 && hawb3)) ? 'service' : ''}
                                    readOnly={importerId !== 'NONE' || (mawb3 && hawb3)}
                                    tabIndex="-1"
                                />
                            </FormGroup>
                        </Col>


                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">HAWB No.</Label>
                                <Input
                                    type="text" name="hawb"
                                    className="form-control"
                                    value={hawb.startsWith('000') ? '' : hawb}
                                    maxLength={30}
                                    style={{ borderColor: errors.hawb ? '#f52b2b' : '', }}
                                    onChange={(e) => setHawb(e.target.value)}
                                    id={(mawb3 && hawb3) ? 'service' : 'mawb'}
                                    readOnly={(mawb3 && hawb3)}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ' || e.keyCode === 32) {
                                            e.preventDefault();
                                        }
                                    }}
                                />

                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Package Content Type</Label> {niptStatus === "N" ? <span className="required">*</span> : ''}
                                <Select
                                    options={pcktype}
                                    value={{ value: packageContentType, label: packageContentType }}
                                    onChange={handlepckgtype}
                                    isClearable
                                    className={errors.packageContentType ? 'error-border' : ''}
                                    // id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    // readOnly={mawb3 && hawb3}
                                    isDisabled={mawb3 && hawb3}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #ccc'
                                            }
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none'
                                        }),
                                        dropdownIndicator: () => ({
                                            display: 'none'
                                        })
                                    }}
                                />
                                {errors.packageContentType && (
                                    <div className="error-message">
                                        {errors.packageContentType}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                    </Row>


                    {/* 5Th */}


                    <Row>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">No. of Packages </Label><span className="required">*</span>

                                <Input type="text" name="nop"
                                    className={`form-control ${errors.nop ? 'error-border' : ''}`}
                                    value={nop}
                                    maxLength={4}
                                    onChange={handleNopChange}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />
                                {errors.nop && (
                                    <div className="error-message">
                                        {errors.nop}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">UOM Package Type</Label>
                                <Input
                                    type="text" name="uomPackages"
                                    className="form-control"
                                    onChange={(e) => setUomPackages(e.target.value)}
                                    value={uomPackages}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">CHA_CDE</Label>
                                <Input
                                    type="text" name="chaCde"
                                    className="form-control"
                                    onChange={(e) => setChaCde(e.target.value)}
                                    value={chaCde}
                                    maxLength={10}
                                    id='service'
                                    readOnly
                                    tabIndex="-1"
                                />
                            </FormGroup>
                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">CHA</Label>
                                <Select
                                    options={chas}
                                    placeholder="Select cha"
                                    isClearable
                                    value={chanameByCode}
                                    onChange={handleCHAChange}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                    filterOption={customFilterOption}
                                    // className={errors.chaName ? 'error-border' : ''}
                                    isDisabled={mawb3 && hawb3}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                border: '1px solid #ccc'
                                            }
                                        }),
                                        indicatorSeparator: () => ({
                                            display: 'none'
                                        }),
                                        dropdownIndicator: () => ({
                                            display: 'none'
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            display: 'flex',
                                            color: 'gray',
                                        }),
                                    }}
                                />
                            </FormGroup>
                        </Col>





                    </Row>





                    {/* 6th */}
                    <Row>



                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Assesable In INR</Label> {niptStatus === 'Y' ? <span className="required">*</span> : ''}
                                <Input
                                    type="text" name="assessableValue"
                                    className={`form-control ${errors.assessableValue ? 'error-border' : ''}`}
                                    value={assessableValue}
                                    maxLength={15}
                                    // onChange={(e) => setAssessableValue(handleInputChange(e))}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setErrors(prevErrors => ({ ...prevErrors, assessableValue: '' }));
                                        setAssessableValue(handleInputChange(e));
                                    }}


                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />
                                {errors.assessableValue && (
                                    <div className="error-message">
                                        {errors.assessableValue}
                                    </div>
                                )}

                            </FormGroup>
                        </Col>

                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Desc of Goods </Label>

                                <Input type="text" name="descriptionOfGoods"
                                    className="form-control"
                                    value={descriptionOfGoods}
                                    maxLength={50}
                                    onChange={(e) => setDescriptionOfGoods(e.target.value)}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />
                            </FormGroup>
                        </Col>



                        <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Gross Weight </Label> <span className="required">*</span>

                                {/* <Input type="number" name="grossWeight"
                                    className={errors.grossWeight ? 'error-border' : ''}
                                    value={grossWeight}
                                    maxLength={10}
                                    onChange={(e) => setGrossWeight(e.target.value)}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                /> */}

                                <Input
                                    type="text"
                                    name="grossWeight"
                                    className={`${errors.grossWeight ? 'error-border' : ''}`}
                                    value={grossWeight}
                                    maxLength={10}
                                    // onChange={(e) => setGrossWeight(handleInputChange(e))}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setErrors(prevErrors => ({ ...prevErrors, grossWeight: '' }));
                                        setGrossWeight(handleInputChange(e));
                                    }}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />

                                {errors.grossWeight && (
                                    <div className="error-message">
                                        {errors.grossWeight}
                                    </div>
                                )}
                            </FormGroup>
                        </Col>
                        {/* <Col md={3} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">UOM Weight</Label>
                                <Input
                                    type="text" name="uomWeight"
                                    className="form-control"
                                    onChange={(e) => setuomWeight(e.target.value)}
                                    value={uomWeight}
                                    maxLength={10}
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    readOnly={mawb3 && hawb3}
                                />
                            </FormGroup>
                        </Col> */}



                        <Col md={3}>
                            <FormGroup style={{ marginLeft: '10px' }}>
                                <Label className="forlabel" for="branchId">UOM Weight</Label>
                                <Input
                                    type="select"
                                    name="uomWeight"
                                    value={uomWeight}
                                    className="form-control"
                                    id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                    disabled={mawb3 && hawb3}
                                    onChange={(e) => setuomWeight(e.target.value)}
                                >
                                    <option value="GMS">GMS</option>
                                    <option value="KGS">KGS</option>
                                </Input>
                            </FormGroup>
                        </Col>




                    </Row>

                    {/* 7th */}

                    {(userType !== "Cargo Official" && userType !== "Cargo Gate" && userType !== "Cargo Custodian") ? (

                        <Row>

                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">BE Request Id</Label> {niptStatus === 'Y' ? <span className="required">*</span> : ''}
                                    <Input
                                        type="text" name="beRequestId"
                                        className={`form-control ${errors.beRequestId ? 'error-border' : ''}`}
                                        value={beRequestId}
                                        maxLength={20}
                                        // onChange={(e) => setBeRequestId(e.target.value)}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setErrors(prevErrors => ({ ...prevErrors, beRequestId: '' }));
                                            setBeRequestId(value);
                                        }}
                                        id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                        readOnly={mawb3 && hawb3}
                                    />

                                    {errors.beRequestId && (
                                        <div className="error-message">
                                            {errors.beRequestId}
                                        </div>
                                    )}

                                </FormGroup>
                            </Col>

                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">BE Number </Label> {niptStatus === 'Y' ? <span className="required">*</span> : ''}

                                    <Input type="text" name="beNo"
                                        className={`form-control ${errors.beNo ? 'error-border' : ''}`}
                                        value={beNo}
                                        maxLength={18}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setErrors(prevErrors => ({ ...prevErrors, beNo: '' }));
                                            setBeNo(value);
                                        }}
                                        id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                        readOnly={mawb3 && hawb3}
                                    // tabIndex="-1"
                                    />
                                    {errors.beNo && (
                                        <div className="error-message">
                                            {errors.beNo}
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">BE Date</Label> {niptStatus === 'Y' ? <span className="required">*</span> : ''}
                                    <div> {/* Wrap in an input group */}
                                        <DatePicker
                                            selected={beDate}
                                            onChange={handleDateChangeBE}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            dateFormat="dd/MM/yyyy"
                                            value={beDate}
                                            className={`form-control ${errors.beDate ? 'error-border' : ''}`}
                                            customInput={<input style={{ width: '100%' }} />}
                                            id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                            readOnly={mawb3 && hawb3}
                                        // tabIndex="-1"
                                        />
                                        {errors.beDate && (
                                            <div className="error-message">
                                                {errors.beDate}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Importer Address</Label>
                                    <textarea
                                        name="importAddress"
                                        rows="2"
                                        className="form-control"
                                        style={{ width: '100%' }}
                                        maxLength={50}
                                        value={importAddress}
                                        onChange={(e) => setImportAddress(e.target.value)}
                                        id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                        readOnly={mawb3 && hawb3}
                                        tabIndex="-1"
                                    ></textarea>
                                </FormGroup>
                            </Col>
                        </Row>
                    ) : null}



                    <Row>


                        {((userType !== "Cargo Official" && userType !== "Cargo Gate" && userType !== "Cargo Custodian") && pcStatus !== 'Y') ? (
                            <Col md={3} style={{ paddingRight: '0px', paddingLeft: '15px' }}>
                                <FormGroup>
                                    <div className="d-flex flex-column align-items-start">
                                        <Label className="forlabel" for="importCheck">Nipt Parcel</Label>
                                        <Input
                                            type="checkbox"
                                            className="form-check-input radios"
                                            style={{ width: 30, height: 30 }}
                                            checked={niptStatus === 'Y'}
                                            onChange={handleCheckboxChange}
                                            id={((mawb3 && hawb3) || dgdc_Status === 'Exit from DGDC SEEPZ Gate' ? 'service' : '')}
                                            disabled={(mawb3 && hawb3) || dgdc_Status === 'Exit from DGDC SEEPZ Gate'}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        ) : null}


                        {((userType !== "Cargo Official" && userType !== "Cargo Gate") && pcStatus === 'Y') ? (


                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Detention No.</Label>
                                    <Input
                                        type="text" name="portOrigin"
                                        className="form-control"
                                        id={mawb3 && hawb3 ? 'service' : 'mawb'}
                                        readOnly={mawb3 && hawb3}
                                        maxLength={30}
                                        value={detentionReceiptNo}
                                        onChange={(e) => setdetentionReceiptNo(e.target.value)}
                                        tabIndex="-1"
                                    />
                                </FormGroup>
                            </Col>
                        ) : null}
                    </Row>






                    <div className="text-center mb-3 mt-2">

                        {!mawb3 && !hawb3 && (  // Show the "SUBMIT" button if mawb3 and hawb3 are NOT present
                            <Button
                                type="button"

                                variant="outline-success"
                                style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
                                onClick={SaveorSubmit}
                                disabled={isSubmitting}
                            >
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                Submit
                            </Button>
                        )}

                        {!mawb3 && !hawb3 && closeStatus !== 'Y' && (  // Show the "ADD NEW" button if mawb3 and hawb3 are NOT present
                            <Button
                                type="button"

                                variant="outline-danger"
                                style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
                                onClick={makefieldEmpty}
                            >
                                <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
                                Clear
                            </Button>
                        )}


                        {closeStatus !== 'Y' && ImportsofMAWB.length > 0 && (
                            <Button
                                type="button"
                                variant="outline-danger"
                                style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
                                onClick={updateImportAll}
                            >
                                <FontAwesomeIcon icon={faClose} style={{ marginRight: '5px' }} />
                                Close
                            </Button>
                        )}


                        <Button
                            type="button"
                            className="widthbtn"
                            variant="outline-primary"
                            style={{ marginLeft: '10px', fontWeight: 'bold' }}
                            onClick={Handleback}
                        >
                            <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
                            BACK
                        </Button>

                    </div>





                    {!(mawb3 && hawb3) && ImportsofMAWB.length > 0 && (
                        <div className="table-responsive">
                            <Table className="table table-striped table-hover">
                                <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                                    <tr className="text-center">
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Sr</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">MAWB No</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">HAWB No</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">SIR No</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Wt</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Content Type</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">No of Packages</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Remark</th>
                                        <th style={{ backgroundColor: '#BADDDA' }} scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {ImportsofMAWB.map((import2, index) =>

                                        <tr className="text-center">
                                            <td className="table-column">{index + 1}</td>
                                            <td className="table-column">{import2.mawb}</td>
                                            <td className="table-column">{import2.hawb.startsWith('000') ? '' : import2.hawb}</td>
                                            <td className="table-column">{import2.sirNo}</td>
                                            <td className="table-column">{import2.grossWeight}</td>
                                            <td className="table-column">{import2.packageContentType}</td>
                                            <td className="table-column">{import2.nop}</td>
                                            <td className="table-column">{import2.importRemarks}</td>
                                            <td className="table-column">
                                                <button
                                                    type="button"
                                                    className="btn me-md-2  btn-outline-primary"
                                                    onClick={(e) => getByMAWBnoAndHAwb(import2.companyId, import2.branchId, import2.impTransId, import2.mawb, import2.hawb, import2.sirNo)}
                                                ><FontAwesomeIcon icon={faEdit} />

                                                </button>


                                                {dgdc_Status === 'Handed over to DGDC Cargo' && (
                                                    <button
                                                        type="button"
                                                        className="btn gap-2  btn-outline-danger"
                                                        onClick={(e) => DeleteImport(import2.companyId, import2.branchId, import2.impTransId, import2.mawb, import2.hawb, import2.sirNo)}
                                                    > <FontAwesomeIcon icon={faTrash} />

                                                    </button>

                                                )}

                                            </td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )}
                </CardBody>
            </Card >


        </div>
    );
}

export default Import_Model;



